import React, { useState, useContext, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { useQuery } from '@apollo/react-hooks'
import { Pagination, Select } from 'antd'
import _get from 'lodash/get'
import { GET_NLV_INDIVIDUAL_REPORT } from 'modules/Reports/graphql/Queries'
// import ChartLoader from 'components/loaders/ChartLoader'
import ChartError from 'components/Page404/ChartError'
import { exportGraphAsImage } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import { AppContext } from 'AppContext'
import '../index.css'

const Option = Select.Option

export default function () {
  const {
    state: { selectedGroup },
  } = useContext(AppContext)
  const [selectedField, setSelectedField] = useState('NLV')
  const { data, loading, error } = useQuery(GET_NLV_INDIVIDUAL_REPORT, {
    variables: { groupId: selectedGroup },
    fetchPolicy: 'network-only',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [renderData, setRenderData] = useState([])
  const [total, setTotal] = useState(0)
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)

  // if (loading) return <ChartLoader title='NLV Individual Report' />
  // if (!_get(data, 'nlvIndividualReport.length')) return <ChartError title='NLV Individual Report' desc='No Data' />

  let nlvIndividualReport
  if (!loading && _get(data, 'nlvIndividualReport.length')) {
    nlvIndividualReport = data.nlvIndividualReport
  }

  const isEmpty = !_get(data, 'nlvIndividualReport.length')

  useEffect(() => {
    let newRenderData
    if (nlvIndividualReport) {
      newRenderData = nlvIndividualReport.map((reportData) => ({
        name: reportData.Client,
        ...reportData,
      }))
      setTotal(newRenderData?.length)

      setRenderData(newRenderData.slice(skip, skip + limit))
    }
  }, [skip, limit, nlvIndividualReport])

  useEffect(() => {
    const handleResize = () => {
      setLimit(window.innerWidth < 749 ? 2 : 10)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (error) return <ChartError title='NLV Individual Report' />

  function handleChange(value) {
    setCurrentPage(1)
    setSkip(0)
    setSelectedField(value)
  }

  const divId = 'export-graph-NLV-individual-chart'

  function handleExport(value) {
    exportGraphAsImage('NLV-Individual-Report', value, divId)
  }

  const formatYAxis = (value) => {
    const absValue = Math.abs(value)
    let formattedValue = ''

    if (absValue >= 10000000) {
      formattedValue = `${(absValue / 10000000).toFixed(2)} Cr`
    } else if (absValue >= 100000) {
      formattedValue = `${(absValue / 100000).toFixed(2)} L`
    } else {
      formattedValue = absValue.toLocaleString('en-IN')
    }

    return value < 0 ? `- ${formattedValue}` : formattedValue
  }

  const CustomTooltip = (props) => {
    if (!props.active) {
      return null
    } else {
      const data = props?.payload[0]?.payload
      return (
        <div className='custom-tooltip-1'>
          <span className='tooltip-title'>{data?.name}</span>
          <span className='tooltip-title-1'>Name : {data?.Name}</span>
          <span className='tooltip-title-1'>{`${selectedField} : ${Math.round(
            data[selectedField]
          )}`}</span>
        </div>
      )
    }
  }

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <ul className='performance_chart_legend_ul'>
        {payload.map((entry, index) => {
          return (
            <>
              <li className='performance_chart_legend_li' key={`item-${index}`}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <svg width='10' height='10' style={{ marginRight: 5 }}>
                      <circle cx='5' cy='5' r='5' fill={entry.color} />
                    </svg>
                  </div>
                  <div className='nlv_report_data_value'>{entry.value}</div>
                </div>
              </li>
            </>
          )
        })}
      </ul>
    )
  }

  const onChange = (page) => {
    setSkip((page > 1 ? page : 0) * limit)
    setLimit(limit)
    setCurrentPage(page)
  }

  return (
    // <Widget
    //   title={
    //     <h2 className='h4 gx-text-capitalize gx-mb-0'>NLV Individual Report</h2>
    //   }
    //   styleName='gx-text-center'
    // >
    <div className='nlv_report_main_div'>
      <div className='nlv_chart_sub_div'>
        <div className='nlv_report_title'>NLV Individual Report</div>
        <div className='nlv_report_options'>
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            className='display-field'
            defaultValue=''
            onChange={handleChange}
            style={{ minWidth: 170 }}
          >
            <Option value='' disabled>
              Select Field
            </Option>
            <Option value='StockPnl'>Stock PNL</Option>
            <Option value='POSPnl'>POSPnl</Option>
            <Option value='LedgerBalance'>Ledger Balance</Option>
            <Option value='PnlBooked'>PNL Booked</Option>
            <Option value='PnlunBooked'>PNL Unbooked</Option>
            <Option value='PnlWithOutInterest'>PNL Without Interest</Option>
            <Option value='InterestSaved'>Interest Saved</Option>
            <Option value='PnlAfterInterest'>PNL After Interest</Option>
            <Option value='ProffitPayOut'>Profit PayOut</Option>
            <Option value='TotalExposure'>Total Exposure</Option>
            <Option value='StockReceived'>Stock Received</Option>
            <Option value='NLV'>NLV</Option>
          </Select>
          &nbsp;
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            className='display-field'
            value='1'
            onChange={handleExport}
          >
            <Option value='1' disabled>
              Export As
            </Option>
            <Option value='png'>PNG</Option>
            <Option value='jpeg'>JPEG</Option>
          </Select>
        </div>
      </div>
      {loading ? (
        <div className='performance_loading'>
          <Loader />
        </div>
      ) : isEmpty ? (
        <h2 className='h4 gx-text-capitalize gx-mb-0 performance_loading'>
          No Data
        </h2>
      ) : (
        <div>
          <ResponsiveContainer width='100%' height={250} id={divId}>
            <BarChart
              data={renderData}
              margin={{
                top: 20,
                right: 30,
                left: 50,
                bottom: 5,
              }}
            >
              <XAxis dataKey='name' />
              <YAxis
                tickFormatter={formatYAxis}
                domain={['auto', 'auto']}
                allowDataOverflow={true}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                layout='vertical'
                verticalAlign='bottom'
                align='center'
                content={renderLegend}
              />
              <Bar dataKey={selectedField} fill='#3E97FF' />
            </BarChart>
          </ResponsiveContainer>
          <div className='nlv-pagination'>
            <Pagination
              current={currentPage}
              onChange={onChange}
              pageSize={limit}
              total={total - limit}
            />
          </div>
        </div>
      )}
    </div>
    // </Widget>
  )
}
