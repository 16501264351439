import React, { useState, useContext, useEffect } from 'react'
import {
  Button,
  Input,
  Space,
  DatePicker,
  Select,
  Form,
  Popover,
  Drawer,
} from 'antd'
import {
  SearchOutlined,
  FilterOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'

import TradeFOTable from './TradeFOTable'
import { GET_TRADER_FO_REPORT } from '../graphql/Queries'
import '../index.css'
import { AppContext } from 'AppContext'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import { getName } from 'utils/User'
import moment from 'moment'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import { CSVLink } from 'react-csv'
import Loader from 'components/loaders/Loader'

const { RangePicker } = DatePicker
const { Option } = Select

export default function () {
  const {
    state: {
      isAdmin,
      isClient,
      currentUser: { clientCode, firstName, lastName, role },
      isMobile,
    },
  } = useContext(AppContext)

  const [form] = Form.useForm()
  const [filters, setFilters] = useState({
    clientCode: !isAdmin ? clientCode : '',
  })
  const [data, setData] = useState()
  const [open, setOpen] = useState(false)
  const [selectedClientName, setSelectedClientName] = useState({
    ClientName: !isAdmin ? `${(firstName, ' ', lastName)}` : '',
  })
  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
      fetchPolicy: 'network-only',
    }
  )

  const { data: allData, loading } = useQuery(GET_TRADER_FO_REPORT, {
    variables: { where: { clientCode: filters?.clientCode } },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isEmpty(filters)) {
      setData(allData)
    }
  }, [allData, filters, loading])

  const onFinish = (values) => {
    setFilters(() => {
      return {
        ...filters,
        clientCode: values?.clientCode,
        clientName: selectedClientName,
      }
    })
    setOpen(false)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      let defaultValue = []
      const from = `fromDate`
      const to = `toDate`
      if (!isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
            }}
          />
        </div>
      )
    },
  })
  if (loading) return <Loader />
  let staticHtml = ``
  if (get(data, 'spTradeReportFO')) {
    staticHtml = `
    <div class="ant-table-wrapper nlv-tracker-report">
  <div class="ant-spin-nested-loading">
    <div class="ant-spin-container">
      <div class="ant-table ant-table-ping-right ant-table-fixed-header ant-table-scroll-horizontal">
        <div class="ant-table-container">
          <div class="ant-table-header" style="overflow: hidden;">
            <table style="table-layout: fixed;">
              <colgroup>
                <col style="width: 53px; min-width: 53px;">
                <col style="width: 61px; min-width: 61px;">
                <col style="width: 50px; min-width: 50px;">
                <col style="width: 53px; min-width: 53px;">
                <col style="width: 57px; min-width: 57px;">
                <col style="width: 32px; min-width: 32px;">
                <col style="width: 87px; min-width: 87px;">
                <col style="width: 53px; min-width: 53px;">
                <col style="width: 76px; min-width: 76px;">
                <col style="width: 49px; min-width: 49px;">
                <col style="width: 74px; min-width: 74px;">
                <col style="width: 59px; min-width: 59px;">
                <col style="width: 17px; min-width: 17px;">
              </colgroup>
              <thead class="ant-table-thead">
                <tr>
                  <th colspan="12" class="ant-table-cell report-title main-title">Trade FO Report</th>
                  <th class="ant-table-cell ant-table-cell-scrollbar" rowspan="2"></th>
                </tr>
                <tr>
                  <th class="ant-table-cell report-text">
                    <div class="ant-table-filter-column"><span class="ant-table-filter-column-title">Entry
                        Date</span><span class="ant-table-filter-trigger-container"><span role="button" tabindex="-1"
                          class="ant-table-filter-trigger ant-dropdown-trigger"><span role="img" aria-label="filter"
                            class="anticon anticon-filter"><svg viewBox="64 64 896 896" focusable="false"
                              data-icon="filter" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                              <path
                                d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z">
                              </path>
                            </svg></span></span></span></div>
                  </th>
                  <th class="ant-table-cell report-text">Instrument Name</th>
                  <th class="ant-table-cell report-text">Company</th>
                  <th class="ant-table-cell report-text">M-date</th>
                  <th class="ant-table-cell report-numeric">Strike Rate</th>
                  <th class="ant-table-cell report-text">cp</th>
                  <th class="ant-table-cell report-text">Script</th>
                  <th class="ant-table-cell report-numeric">Qty</th>
                  <th class="ant-table-cell report-numeric">Rate</th>
                  <th class="ant-table-cell report-numeric ant-table-column-has-sorters">
                    <div class="ant-table-filter-column"><span class="ant-table-filter-column-title">
                        <div class="ant-table-column-sorters-with-tooltip">
                          <div class="ant-table-column-sorters"><span>Strategy Code</span><span
                              class="ant-table-column-sorter ant-table-column-sorter-full"><span
                                class="ant-table-column-sorter-inner"><span role="img" aria-label="caret-up"
                                  class="anticon anticon-caret-up ant-table-column-sorter-up"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z">
                                    </path>
                                  </svg></span><span role="img" aria-label="caret-down"
                                  class="anticon anticon-caret-down ant-table-column-sorter-down"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z">
                                    </path>
                                  </svg></span></span></span></div>
                        </div>
                      </span><span class="ant-table-filter-trigger-container"><span role="button" tabindex="-1"
                          class="ant-table-filter-trigger ant-dropdown-trigger"><span role="img" aria-label="search"
                            class="anticon anticon-search"><svg viewBox="64 64 896 896" focusable="false"
                              data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                              <path
                                d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">
                              </path>
                            </svg></span></span></span></div>
                  </th>
                  <th class="ant-table-cell report-text ant-table-column-has-sorters">
                    <div class="ant-table-filter-column"><span class="ant-table-filter-column-title">
                        <div class="ant-table-column-sorters-with-tooltip">
                          <div class="ant-table-column-sorters"><span>
                         ${
                           data.spTradeReportFO.filter(
                             (report, key) => key === 0
                           )[0]?.ClientCode
                         }
                          </span><span
                              class="ant-table-column-sorter ant-table-column-sorter-full"><span
                                class="ant-table-column-sorter-inner"><span role="img" aria-label="caret-up"
                                  class="anticon anticon-caret-up ant-table-column-sorter-up"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z">
                                    </path>
                                  </svg></span><span role="img" aria-label="caret-down"
                                  class="anticon anticon-caret-down ant-table-column-sorter-down"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z">
                                    </path>
                                  </svg></span></span></span></div>
                        </div>
                      </span><span class="ant-table-filter-trigger-container"><span role="button" tabindex="-1"
                          class="ant-table-filter-trigger ant-dropdown-trigger"><span role="img" aria-label="search"
                            class="anticon anticon-search"><svg viewBox="64 64 896 896" focusable="false"
                              data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                              <path
                                d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">
                              </path>
                            </svg></span></span></span></div>
                  </th>
                  <th class="ant-table-cell report-text ant-table-column-has-sorters">
                    <div class="ant-table-filter-column"><span class="ant-table-filter-column-title">
                        <div class="ant-table-column-sorters-with-tooltip">
                          <div class="ant-table-column-sorters"><span>
                           ${
                             data.spTradeReportFO.filter(
                               (report, key) => key === 0
                             )[0]?.Membercode
                           }
                          </span><span
                              class="ant-table-column-sorter ant-table-column-sorter-full"><span
                                class="ant-table-column-sorter-inner"><span role="img" aria-label="caret-up"
                                  class="anticon anticon-caret-up ant-table-column-sorter-up"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z">
                                    </path>
                                  </svg></span><span role="img" aria-label="caret-down"
                                  class="anticon anticon-caret-down ant-table-column-sorter-down"><svg
                                    viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z">
                                    </path>
                                  </svg></span></span></span></div>
                        </div>
                      </span><span class="ant-table-filter-trigger-container"><span role="button" tabindex="-1"
                          class="ant-table-filter-trigger ant-dropdown-trigger"><span role="img" aria-label="search"
                            class="anticon anticon-search"><svg viewBox="64 64 896 896" focusable="false"
                              data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                              <path
                                d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">
                              </path>
                            </svg></span></span></span></div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="ant-table-body"
            style="overflow: scroll; max-height: calc((((((100vh - 72px) - 24px) - 24px) - 24px) - 40px) - 65px);">
            <table style="width: auto; min-width: 100%; table-layout: fixed;">
              <colgroup></colgroup>
              <tbody class="ant-table-tbody">
                <tr aria-hidden="true" class="ant-table-measure-row" style="height: 0px;">
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                  <td style="padding: 0px; border: 0px; height: 0px;"></td>
                </tr>`
    staticHtml = `${staticHtml}`
    data.spTradeReportFO.forEach((data) => {
      const {
        entrydate,
        instrumentname,
        company,
        mdate,
        strikerate,
        cp,
        Script,
        qty,
        rate,
        StrategyCode,
        ClientCode,
        MemberCode,
      } = data
      staticHtml = `${staticHtml}
                <tr data-row-key="0" class="ant-table-row ant-table-row-level-0">
                  <td class="ant-table-cell report-text">${entrydate}</td>
                  <td class="ant-table-cell report-text">${instrumentname}</td>
                  <td class="ant-table-cell report-text">${company}</td>
                  <td class="ant-table-cell report-text">${mdate}</td>
                  <td class="ant-table-cell report-numeric">${strikerate}</td>
                  <td class="ant-table-cell report-text">${cp}</td>
                  <td class="ant-table-cell report-text">${Script}</td>
                  <td class="ant-table-cell report-numeric">${qty}</td>
                  <td class="ant-table-cell report-numeric">${rate.toFixed(
                    2
                  )}</td>
                  <td class="ant-table-cell report-numeric">${StrategyCode}</td>
                  <td class="ant-table-cell report-text">${ClientCode}</td>
                  <td class="ant-table-cell report-text">${MemberCode}</td>
                </tr>`
    })
    staticHtml = `${staticHtml}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ul class="ant-pagination ant-table-pagination ant-table-pagination-right" unselectable="unselectable">
        <li title="Previous Page" class="ant-pagination-prev ant-pagination-disabled" aria-disabled="true"><a
            class="ant-pagination-item-link" disabled=""><span role="img" aria-label="left"
              class="anticon anticon-left"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em"
                height="1em" fill="currentColor" aria-hidden="true">
                <path
                  d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z">
                </path>
              </svg></span></a></li>
        <li title="1" class="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active" tabindex="0"><a>1</a>
        </li>
        <li title="2" class="ant-pagination-item ant-pagination-item-2" tabindex="0"><a>2</a></li>
        <li title="3" class="ant-pagination-item ant-pagination-item-3" tabindex="0"><a>3</a></li>
        <li title="4" class="ant-pagination-item ant-pagination-item-4" tabindex="0"><a>4</a></li>
        <li title="5" class="ant-pagination-item ant-pagination-item-5 ant-pagination-item-before-jump-next"
          tabindex="0"><a>5</a></li>
        <li title="Next 5 Pages" tabindex="0" class="ant-pagination-jump-next ant-pagination-jump-next-custom-icon"><a
            class="ant-pagination-item-link">
            <div class="ant-pagination-item-container"><span role="img" aria-label="double-right"
                class="anticon anticon-double-right ant-pagination-item-link-icon"><svg viewBox="64 64 896 896"
                  focusable="false" data-icon="double-right" width="1em" height="1em" fill="currentColor"
                  aria-hidden="true">
                  <path
                    d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z">
                  </path>
                </svg></span><span class="ant-pagination-item-ellipsis">•••</span></div>
          </a></li>
        <li title="168" class="ant-pagination-item ant-pagination-item-168" tabindex="0"><a>168</a></li>
        <li title="Next Page" tabindex="0" class="ant-pagination-next" aria-disabled="false"><a
            class="ant-pagination-item-link"><span role="img" aria-label="right" class="anticon anticon-right"><svg
                viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor"
                aria-hidden="true">
                <path
                  d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                </path>
              </svg></span></a></li>
        <li class="ant-pagination-options">
          <div class="ant-select ant-pagination-options-size-changer ant-select-single ant-select-show-arrow">
            <div class="ant-select-selector"><span class="ant-select-selection-search"><input autocomplete="off"
                  class="ant-select-selection-search-input" role="combobox" aria-haspopup="listbox"
                  aria-owns="rc_select_1_list" aria-autocomplete="list" aria-controls="rc_select_1_list"
                  aria-activedescendant="rc_select_1_list_0" readonly="" value="" id="rc_select_1"
                  style="opacity: 0;"></span><span class="ant-select-selection-item">100 / page</span></div><span
              class="ant-select-arrow" unselectable="on" aria-hidden="true" style="user-select: none;"><span role="img"
                aria-label="down" class="anticon anticon-down"><svg viewBox="64 64 896 896" focusable="false"
                  data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path
                    d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                  </path>
                </svg></span></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
`
  }

  const child = [
    {
      title: 'Entry Date',
      dataIndex: 'entrydate',
      key: 'entrydate',
      className: 'report-text',
      ...getColumnDateProps('entrydate'),
      width: 140,
    },
    {
      title: 'Instrument Name',
      dataIndex: 'instrumentname',
      key: 'instrumentname',
      className: 'report-text',
      width: 120,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      className: 'report-text',
      width: 100,
    },
    {
      title: 'M-date',
      dataIndex: 'mdate',
      key: 'mdate',
      className: 'report-text',
      width: 110,
    },
    {
      title: 'Strike Rate',
      dataIndex: 'strikerate',
      key: 'strikerate',
      className: 'report-numeric',
      width: 120,
    },
    {
      title: 'cp',
      dataIndex: 'cp',
      key: 'cp',
      className: 'report-text',
      width: 50,
    },
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
      className: 'report-text',
      width: 310,
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
      className: 'report-numeric',
      width: 60,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      className: 'report-numeric',
      width: 80,
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Strategy Code',
      dataIndex: 'StrategyCode',
      key: 'StrategyCode',
      className: 'report-numeric',
      ...getColumnSearchProps('strategyCode'),
      width: 150,
    },
    role === 'CLIENT'
      ? {
          title: 'Client Code',
          dataIndex: 'ClientCode',
          key: 'ClientCode',
          className: 'report-text',
          width: 150,
        }
      : {
          title: 'Client Code',
          dataIndex: 'ClientCode',
          key: 'ClientCode',
          className: 'report-text',
          ...getColumnSearchProps('clientCode'),
          width: 150,
        },
    {
      title: 'Member Code',
      dataIndex: 'MemberCode',
      key: 'MemberCode',
      className: 'report-text',
      ...getColumnSearchProps('memberCode'),
      width: 150,
    },
  ]

  const columns =
    role !== 'CLIENT'
      ? isAdmin
        ? child
        : child?.filter((field) => !field?.title?.includes('Member Code'))
      : child?.filter(
          (item) =>
            item?.title === 'Script' ||
            item?.title === 'Qty' ||
            item?.title === 'Rate' ||
            item?.title === 'Entry Date' ||
            item?.title === 'Client Code'
        )

  const content = (
    <div style={{ width: '100px' }}>
      <CSVLink
        style={{ paddingRight: 0 }}
        data={allData?.spTradeReportFO?.map((item) => {
          return {
            Script: item?.Script,
            Quantity: item?.qty,
            Rate: item?.rate?.toFixed(2),
            EntryDate: item?.entrydate,
            ClientCode: item?.ClientCode,
          }
        })}
        className='csv-btn'
        filename={`TRADER-FO-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  const contentAll = (
    <div style={{ width: '100px' }}>
      <CSVLink
        style={{ paddingRight: 0 }}
        data={allData?.spTradeReportFO?.map((item) => {
          return { ...item, Rate: item?.rate?.toFixed(2) }
        })}
        className='csv-btn'
        filename={`TRADER-FO-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  return (
    <>
      {/* {role !== 'CLIENT' && data?.spTradeReportFO?.length ? (
        <DownloadReport
          data={
            role === 'CLIENT'
              ? JSON.parse(JSON.stringify(data?.spTradeReportFO))?.map(
                  (item) => {
                    return {
                      StrickRate: item.strikerate,
                      CP: item.cp,
                      Script: item.Script,
                      Quantity: item.qty,
                      Rate: item.rate,
                      EntryDate: item.entryDate,
                      ClientCode: item.ClientCode,
                    }
                  }
                )
              : data?.spTradeReportFO
          }
          fileName='TRADER-FO-report'
          id='TRADER-FO-REPORT-PDF'
          staticHtml={staticHtml}
          onlyCSV={true}
        />
      ) : (
        allData?.spTradeReportFO && (
          <DownloadReport
            data={allData?.spTradeReportFO?.map((item) => {
              let obj = {}

              // obj['Strike Rate'] = item.strikerate

              obj = {
                ...obj,
                // CP: item.cp,
                Script: item.Script,
                Quantity: item.qty,
                Rate: item.rate,
                EntryDate: item.entryDate,
                ClientCode: item.ClientCode,
              }

              return obj
            })}
            fileName='TRADER-FO-report'
            id='TRADER-FO-REPORT-PDF'
            staticHtml={staticHtml}
            onlyCSV={true}
          />
        )
      )}

      {role !== 'CLIENT' && allData?.spTradeReportFO?.length ? (
        <DownloadReport
          data={allData?.spTradeReportFO}
          fileName='TRADER-FO-report'
          id='TRADER-FO-REPORT-PDF'
          staticHtml={staticHtml}
          onlyCSV={true}
          getAllData={true}
        />
      ) : (
        ''
      )} */}

      {/* {role !== 'CLIENT' && (
        <Form form={form} onFinish={onFinish} name='advanced_search'>
          <Form.Item
            name='clientCode'
            label='Select Client Code'
            rules={[{ required: true, message: 'Please select clientCode!' }]}
            hasFeedback
          >
            <Select
              style={{ width: '200px' }}
              getPopupContainer={(trigger) => trigger.parentElement}
              showSearch
              loading={userLoading}
              placeholder='Select Client Code'
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.children[0] ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase()) ||
                (option?.children[2] ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {!userLoading &&
                allClient &&
                allClient?.getClientUsersForStaff?.users.map((user, key) => {
                  return (
                    <Option
                      key={key}
                      value={user.clientCode}
                      title={getName(user)}
                    >
                      {user.clientCode}-{getName(user)}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
                setData({})
              }}
            >
              Clear
            </Button>
          </div>
        </Form>
      )} */}

      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div
          style={{
            margin: '32px 0 50px 0',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '28px',
              textAlign: 'left',
              color: '#000000',
              marginLeft: '16px',
            }}
          >
            Filter
          </div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
        >
          <div
            style={{
              height: isMobile ? 'calc(100vh - 15rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
            }}
          >
            <Form.Item
              name='clientCode'
              label='Select Client Code'
              rules={[{ required: true, message: 'Please select clientCode!' }]}
              hasFeedback
            >
              <Select
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                showSearch
                loading={userLoading}
                placeholder='Select Client Code'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.children[0] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase()) ||
                  (option?.children[2] ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value, option) => {
                  setSelectedClientName(option?.title)
                }}
              >
                {!userLoading &&
                  allClient &&
                  allClient?.getClientUsersForStaff?.users.map((user, key) => {
                    return (
                      <Option
                        key={key}
                        value={user.clientCode}
                        title={getName(user)}
                      >
                        {user.clientCode}-{getName(user)}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </div>

          <div style={{ display: 'flex', gap: '20px', height: '56px' }}>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({ clientCode: !isAdmin ? clientCode : '' })
                setOpen(false)
                setData({})
              }}
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Search
            </Button>
          </div>
        </Form>
      </Drawer>

      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>Trade FO Report</div>

          <div className='container_Export_Popover'>
            {isAdmin && allData?.spTradeReportFO?.length ? (
              <Popover content={contentAll} placement='bottom' trigger='click'>
                <div className='container_Export_Popover'>
                  <Button>
                    Export All
                    <DownloadOutlined
                      color='#AF0000'
                      style={{ fontSize: '16px' }}
                    />
                  </Button>
                </div>
              </Popover>
            ) : (
              ''
            )}
            <Popover content={content} placement='bottom' trigger='click'>
              <div lassName='container_Export_Popover'>
                <Button>
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            </Popover>

            {role !== 'CLIENT' && (
              <div lassName='container_Export_Popover'>
                <Button
                  style={{
                    color: '#000000',
                    border: '1px solid #919EAB52',
                    backgroundColor: 'white',
                  }}
                  onClick={showDrawer}
                >
                  Filter{' '}
                  <FilterOutlined
                    color='#000000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className='report_first_Container'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='Client_Info_Container'>
              {role === 'CLIENT' && (
                <div className='client_Info'>
                  Client Code:&nbsp;
                  <span>{clientCode}</span>
                </div>
              )}
              <div className='client_Info'>
                Client Name:&nbsp;
                <span>
                  {!isClient ? filters?.clientName : firstName + ' ' + lastName}
                </span>
              </div>
            </div>
          </div>
        </div>

        {role !== 'CLIENT' && !loading ? (
          <div className='report-table-wrapper' id='TRADER-FO-REPORT-PDF'>
            <TradeFOTable
              reportData={data?.spTradeReportFO}
              columns={columns}
              loading={loading}
            />
          </div>
        ) : (
          ''
        )}

        {role === 'CLIENT' ? (
          <div className='report-table-wrapper' id='TRADER-FO-REPORT-PDF'>
            <TradeFOTable
              reportData={allData?.spTradeReportFO}
              columns={columns}
              loading={loading}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
