import React, { useContext, Fragment, useEffect, useState } from 'react'
import { NotificationOutlined } from '@ant-design/icons'
import DashboardActiveIcon from 'assets/images/new-images/sidebarIcons/dashboardActive.svg'
import DashboardIcon from 'assets/images/new-images/sidebarIcons/dashboardNormal.svg'
import ReportIconActive from 'assets/images/new-images/sidebarIcons/reportActive.svg'
import ReportIcon from 'assets/images/new-images/sidebarIcons/reportNormal.svg'
import AMCIconActive from 'assets/images/new-images/sidebarIcons/AMCActive.svg'
import AMCIcon from 'assets/images/new-images/sidebarIcons/AMCNormal.svg'
import BulkReportActive from 'assets/images/new-images/sidebarIcons/bulkreportActive.svg'
import BulkReport from 'assets/images/new-images/sidebarIcons/bulkreportNormal.svg'
import ClientIconActive from 'assets/images/new-images/sidebarIcons/clientsActive.svg'
import ClientIcon from 'assets/images/new-images/sidebarIcons/clientsNormal.svg'
import ComplianceIconActive from 'assets/images/new-images/sidebarIcons/complianceActive.svg'
import ComplianceIcon from 'assets/images/new-images/sidebarIcons/complianceNormal.svg'
import InvoiceIconActive from 'assets/images/new-images/sidebarIcons/invoiceActive.svg'
import InvoiceIcon from 'assets/images/new-images/sidebarIcons/invoiceNormal.svg'
import RevenueIconActive from 'assets/images/new-images/sidebarIcons/revenueActive.svg'
import RevenueIcon from 'assets/images/new-images/sidebarIcons/revenueNormal.svg'
import SettingIconActive from 'assets/images/new-images/sidebarIcons/settingActive.svg'
import SettingIcon from 'assets/images/new-images/sidebarIcons/settingNormal.svg'
import TicketIconActive from 'assets/images/new-images/sidebarIcons/ticketActive.svg'
import TicketIcon from 'assets/images/new-images/sidebarIcons/ticketNormal.svg'
import UserIconActive from 'assets/images/new-images/sidebarIcons/usersActive.svg'
import UserIcon from 'assets/images/new-images/sidebarIcons/usersNormal.svg'
import SupportIconActive from 'assets/images/new-images/sidebarIcons/supportActive.svg'
import SupportIcon from 'assets/images/new-images/sidebarIcons/supportNormal.svg'
import AUAIconActive from 'assets/images/new-images/sidebarIcons/auaActive.svg'
import AUAIcon from 'assets/images/new-images/sidebarIcons/auaNormal.svg'
import ReEvaluateIconActive from 'assets/images/new-images/sidebarIcons/reEvaluateActive.svg'
import ReEvaluateIcon from 'assets/images/new-images/sidebarIcons/reEvaluateNormal.svg'

import { NavLink, useHistory } from 'react-router-dom'
import { Checkbox, Menu, Modal } from 'antd'

import { AppContext } from 'AppContext'
import { useQuery } from '@apollo/react-hooks'
import { GET_MULTIPLE_ADVISORY } from 'modules/PendingAdvisory/graphql/Queries'
import { GET_ALL_PENDING_ADVISORY_FOR_ASSOCIATE } from 'modules/PendingAdvisoryForAssociate/graphql/Queries'
import client from 'apollo'
import { GET_CLIENT_FORM } from 'modules/Settings/graphql/Queries'
import Bitrix24Form from 'modules/Verification/ClientForm'
import { convertNumberToCommaSeparate } from 'utils/utilities'

const { SubMenu } = Menu

export default function SideBarMenu({
  pathname,
  // setSelectSubMenu,
  setVisibleDrawer,
}) {
  const history = useHistory()
  const {
    state: {
      isAdmin,
      isStaff,
      isClient,
      isAssociate,
      currentReport,
      currentUser,
      isMobile,
    },
  } = useContext(AppContext)
  let selectedItem = pathname.split('/')[1]
  if (selectedItem === 'reports') {
    const splitArray = pathname.split('/')
    selectedItem = splitArray[splitArray.length - 1]
  }

  const [advisoryType, setAdvisoryType] = useState([])
  const [pendingAdvisory, setPendingAdvisory] = useState([])
  const [feedbackFormVisible, setFeedbackFormVisible] = useState(false)

  const [clickCount, setClickCount] = useState(0)
  const [timer, setTimer] = useState(null)
  const [allowClicking, setAllowClicking] = useState(true)

  const handleClick = () => {
    console.log(clickCount)
    if (clickCount >= 2) {
      setAllowClicking(false)
      return
    } else {
      setAllowClicking(true)
    }

    setClickCount((prevCount) => prevCount + 1)

    if (!timer) {
      const newTimer = setTimeout(() => {
        setClickCount(0)
        setTimer(null)
        setAllowClicking(true)
      }, 1500)
      setTimer(newTimer)
    }
  }

  const getMultipleAdvisory = () => {
    if (currentUser?.role === 'CLIENT') {
      client
        .query({
          query: GET_MULTIPLE_ADVISORY,
          variables: {
            where: { clientCode: currentUser?.clientCode, id: currentUser?.id },
          },
        })
        .then((res) => {
          setAdvisoryType(
            res?.data?.getMultipleAdvisoryData?.map((item) => item?.type)
          )
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (!isClient && !isAdmin) {
      client
        .query({
          query: GET_ALL_PENDING_ADVISORY_FOR_ASSOCIATE,
          variables: { isMenu: true },
        })
        .then((res) => {
          setPendingAdvisory(res?.data?.getPendingAdvisoryForAssociate)
        })
    }
  }, [pendingAdvisory, isAdmin, isClient])
  useEffect(() => {
    getMultipleAdvisory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let isChecked = false
  const onChange = (e) => {
    isChecked = e.target.checked
  }
  const showModal = localStorage.getItem('pendingAMCModal')
  useEffect(() => {
    if (
      isClient &&
      showModal !== 'true' &&
      currentUser?.completedSteps === 'DONE' &&
      currentUser?.isAmcPending &&
      currentReport.includes('view-amc') &&
      currentUser?.umrnNumber
    ) {
      Modal.warning({
        title: (
          <>
            <p>
              Kindly maintain a sufficient balance in your bank account. The fee
              of Rs.{' '}
              {convertNumberToCommaSeparate(currentUser?.totalDueAmcAmount)}{' '}
              will be automatically debited on the due date as per the
              e-mandate.
            </p>
          </>
        ),
        content: (
          <>
            <Checkbox onChange={onChange}>don’t show again</Checkbox>
          </>
        ),
        onOk: () => {
          localStorage.setItem('pendingAMCModal', isChecked)
        },
      })

      history.push('/amc')
    } else if (
      isClient &&
      !currentUser?.umrnNumber &&
      (pathname !== '/add-aua-verification' ||
        !currentUser?.isNewAuaAdvisoryGenerate) &&
      currentUser?.isAmcPending
    ) {
      Modal.warning({
        title: 'Kindly Pay Your Pending AMC',
        content: `payable amount is Rs. ${convertNumberToCommaSeparate(
          currentUser?.totalDueAmcAmount
        )}/-`,
      })
      history.push('/amc')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentReport, history])

  useEffect(() => {
    if (
      advisoryType?.length &&
      currentUser?.completedSteps === 'DONE' &&
      !currentUser?.isNewAuaAdvisoryGenerate &&
      history?.location?.pathname !== '/faqs'
    ) {
      Modal.warning({
        title: 'Kindly Complete The Pending Advisory',
      })
      history.push(`/pending-advisory`)
    }
  }, [history, currentUser, advisoryType])

  const { refetch } = useQuery(GET_CLIENT_FORM, {
    variables: { where: { formName: 'feedback_form' } },
  })

  const getFeedbackFormStatus = () => {
    if (
      currentUser?.role === 'CLIENT' &&
      currentUser?.completedSteps === 'DONE'
    ) {
      refetch()
        .then((res) => {
          setFeedbackFormVisible(res?.data?.getClientsForm === null)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    getFeedbackFormStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal
        className='feedback_form'
        title={`Feedback`}
        visible={feedbackFormVisible}
        footer={null}
        onCancel={() => {
          setFeedbackFormVisible(!feedbackFormVisible)
        }}
      >
        <div>
          <Bitrix24Form
            isUpdateUser={false}
            setOpenFeedback={setFeedbackFormVisible}
            confirmLoading={false}
          />
        </div>
      </Modal>

      <Menu
        // theme='light'
        mode='inline'
        selectedKeys={[selectedItem || 'dashboard']}
        style={{ marginBottom: '30px' }}
        onClick={handleClick}
      >
        <Menu.Item
          disabled={!allowClicking}
          key='dashboard'
          onClick={() => setVisibleDrawer(isMobile ? true : false)}
        >
          <NavLink to='/' className='nav-text'>
            <img
              src={pathname === '/' ? DashboardActiveIcon : DashboardIcon}
              alt='DashboardActiveIcon'
              style={{ marginRight: '12px' }}
            />
            <span className='sidebar_tab_name'>Dashboard</span>
          </NavLink>
        </Menu.Item>

        <SubMenu
          key='reports'
          className={`${
            pathname.includes('/reports')
              ? 'custom-sub-menu-active'
              : 'custom-sub-menu '
          }`}
          selectedKeys={'/reports/ledger'}
          title={
            <Fragment>
              <img
                src={
                  pathname.includes('/reports') ? ReportIconActive : ReportIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span
                className={`${
                  pathname.includes('/reports/performance')
                    ? 'custom-sub-menu-text-active'
                    : 'custom-sub-menu '
                }`}
              >
                Reports
              </span>
            </Fragment>
          }
        >
          {isClient && currentReport.includes('ILTS') && (
            <Menu.Item disabled={!allowClicking} key='performance'>
              <NavLink
                to='/reports/performance'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/performance'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Performance</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('ledger') && (
            <Menu.Item disabled={!allowClicking} key='ledger'>
              <NavLink
                to='/reports/ledger'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/ledger'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Ledger</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('Ledger Beta') && (
            <Menu.Item disabled={!allowClicking} key='ledger-beta'>
              <NavLink
                to='/reports/ledger-beta'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/ledger'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Ledger Beta</span>
              </NavLink>
            </Menu.Item>
          )}
          {isClient && currentReport.includes('Interest') && (
            <Menu.Item disabled={!allowClicking} key='interest'>
              <NavLink
                to='/reports/interest'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/interest'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Interest</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('Unhedge') && (
            <Menu.Item disabled={!allowClicking} key='unhedge'>
              <NavLink
                to='/reports/unhedge'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/unhedge'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>
                <span style={{ marginLeft: '12px' }}>Unhedge</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('trade-eq') && (
            <Menu.Item disabled={!allowClicking} key='trade-eq'>
              <NavLink
                to='/reports/trade-eq'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/trade-eq'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Trade EQ</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('trade-fo') && (
            <Menu.Item disabled={!allowClicking} key='trade-fo'>
              <NavLink
                to='/reports/trade-fo'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/trade-fo'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Trade FO</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('cost-to-profit') && (
            <Menu.Item disabled={!allowClicking} key='cost-to-profit'>
              <NavLink
                to='/reports/cost-to-profit'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/cost-to-profit'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Cost To Profit</span>
              </NavLink>
            </Menu.Item>
          )}
          {isClient && currentReport.includes('Average Index') && (
            <Menu.Item disabled={!allowClicking} key='average-index'>
              <NavLink
                to='/reports/average-index'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/average-index'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Average Index</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('NLV Mismatch') && (
            <Menu.Item disabled={!allowClicking} key='nlv-mismatch'>
              <NavLink
                to='/reports/nlv-mismatch'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/nlv-mismatch'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>NLV Mismatch</span>
              </NavLink>
            </Menu.Item>
          )}
          {isClient && currentReport.includes('Current Position') && (
            <Menu.Item disabled={!allowClicking} key='current-position'>
              <NavLink
                to='/reports/current-position'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/current-position'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Current Position</span>
              </NavLink>
            </Menu.Item>
          )}
          {isClient && currentReport.includes('NLV Tracker') && (
            <Menu.Item disabled={!allowClicking} key='nlv-tracker'>
              <NavLink
                to='/reports/comparison-of-fund'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/comparison-of-fund'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Comparison of Fund</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('pl Change') && (
            <Menu.Item disabled={!allowClicking} key='pl'>
              <NavLink
                to='/reports/pl'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/pl'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Change in P &amp; L</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('Rollover Tracking') && (
            <Menu.Item disabled={!allowClicking} key='rollover-tracking'>
              <NavLink
                to='/reports/rollover-tracking'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/rollover-tracking'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Rollover Tracking</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('RollOver Opportunity') && (
            <Menu.Item disabled={!allowClicking} key='rollover-opportunity'>
              <NavLink
                to='/reports/rollover-opportunity'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/rollover-opportunity'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Rollover Opportunity</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('Capital Gain') && (
            <Menu.Item disabled={!allowClicking} key='ilts-taxation-report'>
              <NavLink
                to='/reports/ilts-taxation-report'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/ilts-taxation-report'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Taxation Report</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('Scenario Analysis') && (
            <Menu.Item disabled={!allowClicking} key='scenario-analysis'>
              <NavLink
                to='/reports/scenario-analysis'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/scenario-analysis'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Scenario Analysis</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('current-position-j-balance') && (
            <Menu.Item disabled={!allowClicking} key='j-balance'>
              <NavLink
                to='/reports/j-balance'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/j-balance'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>J Balance</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('current-position-eq') && (
            <Menu.Item disabled={!allowClicking} key='position-eq'>
              <NavLink
                to='/reports/position-eq'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/position-eq'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>EQ Position</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('current-position-fo') && (
            <Menu.Item disabled={!allowClicking} key='position-fo'>
              <NavLink
                to='/reports/position-fo'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/position-fo'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>FO Position</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('amc-due-report') && (
            <Menu.Item disabled={!allowClicking} key='amcs-due'>
              <NavLink
                to='/reports/amcs-due'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/amcs-due'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>All Due Amc</span>
              </NavLink>
            </Menu.Item>
          )}
          {isAdmin && (
            <Menu.Item disabled={!allowClicking} key='bulk-amcs'>
              <NavLink
                to='/reports/bulk-amcs'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/bulk-amcs'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Bulk Amcs</span>
              </NavLink>
            </Menu.Item>
          )}
          {currentReport.includes('Performance Summary Report') && (
            <Menu.Item disabled={!allowClicking} key='performance-summary'>
              <NavLink
                to='/reports/performance-summary'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/reports/performance-summary'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Performance Summary</span>
              </NavLink>
            </Menu.Item>
          )}
        </SubMenu>
        {isClient && (
          <Menu.Item
            disabled={!allowClicking}
            key='book-appointment'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/book-appointment' className='nav-text'>
              <img
                src={pathname === '/book-appointment' ? AMCIconActive : AMCIcon}
                alt='appointmentAMCIcon'
                style={{ marginRight: '12px' }}
              />
              <span className='sidebar_tab_name'>Book an Appointment</span>
            </NavLink>
          </Menu.Item>
        )}
        {!(isClient || isAssociate) && (
          <Menu.Item
            disabled={!allowClicking}
            key='users'
            onClick={() => {
              setVisibleDrawer(isMobile ? true : false)
            }}
          >
            <NavLink to='/users' className='nav-text'>
              {/* <UserOutlined /> */}
              <img
                src={pathname.includes('/users') ? UserIconActive : UserIcon}
                alt='UserIcon'
                style={{ marginRight: '12px' }}
              />
              <span>Users</span>
            </NavLink>
          </Menu.Item>
        )}
        {!isClient && (
          <Menu.Item
            disabled={!allowClicking}
            key='clients'
            onClick={() => {
              setVisibleDrawer(isMobile ? true : false)
            }}
          >
            <NavLink to='/clients' className='nav-text'>
              {/* <UsergroupAddOutlined /> */}
              <img
                src={
                  pathname.includes('/clients') ? ClientIconActive : ClientIcon
                }
                alt='ClientIcon'
                style={{ marginRight: '12px' }}
              />
              <span>Clients</span>
            </NavLink>
          </Menu.Item>
        )}
        {isAdmin && (
          <Menu.Item
            disabled={!allowClicking}
            key='compliance'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/compliance' className='nav-text'>
              {/* <ProfileOutlined /> */}
              <img
                src={
                  pathname.includes('/compliance')
                    ? ComplianceIconActive
                    : ComplianceIcon
                }
                alt='ComplianceIcon'
                style={{ marginRight: '12px' }}
              />
              <span>Compliance</span>
            </NavLink>
          </Menu.Item>
        )}
        {(isAdmin || isStaff) && (
          <SubMenu
            key='invoice'
            className='custom-sub-menu'
            title={
              <Fragment>
                {/* <FileAddOutlined /> */}
                <img
                  src={
                    pathname.includes('/invoice') ||
                    pathname.includes('general-invoice') ||
                    pathname.includes('/transactions')
                      ? InvoiceIconActive
                      : InvoiceIcon
                  }
                  alt='InvoiceIcon'
                  style={{ marginRight: '12px' }}
                />
                <span>Invoice</span>
              </Fragment>
            }
          >
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='invoice-master'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/invoice-master' className='nav-text'>
                  {/* <FileDoneOutlined />
                <span>Generate Invoice</span> */}
                  <div
                    className={`${
                      pathname === '/invoice-master'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Generate Invoice</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='transactions'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/transactions' className='nav-text'>
                  {/* <TranslationOutlined />
                <span>Transactions</span> */}
                  <div
                    className={`${
                      pathname === '/transactions'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Transactions</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='general-invoice'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/general-invoice' className='nav-text'>
                  {/* <ProfileOutlined />
                <span>General Setting</span> */}
                  <div
                    className={`${
                      pathname === '/general-invoice'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>General Setting</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='invoice'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/invoice' className='nav-text'>
                  {/* <FileDoneOutlined />
                <span>Invoice Setting</span> */}
                  <div
                    className={`${
                      pathname === '/invoice'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Invoice Setting</span>
                </NavLink>
              </Menu.Item>
            )}

            <Menu.Item disabled={!allowClicking} key='invoice-report'>
              <NavLink
                to='/reports/invoice-report'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                {/* <FileDoneOutlined /> Invoice Report */}
                <div
                  className={`${
                    pathname === '/reports/invoice-report'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Invoice Report</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {currentReport.includes('view-amc') && (
          <Menu.Item
            disabled={!allowClicking}
            key='amc'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/amc' className='nav-text'>
              {/* <CalendarOutlined /> */}
              <img
                src={pathname === '/amc' ? AMCIconActive : AMCIcon}
                alt='AMCIcon'
                style={{ marginRight: '12px' }}
              />
              <span>AMC</span>
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item
          disabled={!allowClicking}
          key='tickets'
          onClick={() => setVisibleDrawer(isMobile ? true : false)}
        >
          <NavLink to='/tickets' className='nav-text'>
            {/* <TagOutlined /> */}
            <img
              src={
                pathname.includes('/tickets') ? TicketIconActive : TicketIcon
              }
              alt='UserIcon'
              style={{ marginRight: '12px' }}
            />
            <span>Tickets</span>
          </NavLink>
        </Menu.Item>
        {!isClient && (
          <Menu.Item
            disabled={!allowClicking}
            key='revenue-income'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/revenue-income' className='nav-text'>
              {/* <WalletOutlined /> */}
              <img
                src={
                  pathname.includes('/revenue-income')
                    ? RevenueIconActive
                    : RevenueIcon
                }
                alt='UserIcon'
                style={{ marginRight: '12px' }}
              />
              <span>Revenue</span>
            </NavLink>
          </Menu.Item>
        )}
        {isAdmin && (
          <SubMenu
            key='bulk-reports'
            className='custom-sub-menu'
            title={
              <Fragment>
                <img
                  src={
                    pathname.includes('/bulk-client')
                      ? BulkReportActive
                      : BulkReport
                  }
                  alt='UserIcon'
                  style={{ marginRight: '12px' }}
                />
                {/* <PieChartOutlined /> */}
                <span>Bulk Report</span>
              </Fragment>
            }
          >
            <Menu.Item disabled={!allowClicking} key='bulk-client-report'>
              <NavLink
                to='/bulk-client-report'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/bulk-client-report'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Multi Report Client</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item disabled={!allowClicking} key='bulk-client-setting'>
              <NavLink
                to='/bulk-client-setting'
                className='nav-text'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <div
                  className={`${
                    pathname === '/bulk-client-setting'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Settings</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {isAdmin && (
          <SubMenu
            key='Logs'
            className='custom-sub-menu'
            title={
              <Fragment>
                {/* <FileAddOutlined /> */}
                <img
                  src={
                    pathname.includes('/client-log') ||
                    pathname.includes('/mail-log')
                      ? InvoiceIconActive
                      : InvoiceIcon
                  }
                  alt='UserIcon'
                  style={{ marginRight: '12px' }}
                />
                <span>Logs</span>
              </Fragment>
            }
          >
            <Menu.Item
              disabled={!allowClicking}
              key='client-log'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/client-log' className='nav-text'>
                {/* <UsergroupAddOutlined />
              <span>Client Log</span> */}
                <div
                  className={`${
                    pathname === '/client-log'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Client Log</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item
              disabled={!allowClicking}
              key='mail-log'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/mail-log' className='nav-text'>
                {/* <MailOutlined />
              <span>Mail Logs</span> */}
                <div
                  className={`${
                    pathname === '/mail-log'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Mail Logs</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {isAdmin && (
          <SubMenu
            key='Mail & PDF Formats'
            className='custom-sub-menu'
            title={
              <Fragment>
                {/* <FileAddOutlined /> */}
                <img
                  src={
                    pathname.includes('/agreement-format') ||
                    pathname.includes('/templates-format') ||
                    pathname.includes('/ticket-mail-format') ||
                    pathname.includes('/amc-mail-format') ||
                    pathname.includes('/messages')
                      ? InvoiceIconActive
                      : InvoiceIcon
                  }
                  alt='UserIcon'
                  style={{ marginRight: '12px' }}
                />
                <span>Mail & PDF Formats</span>
              </Fragment>
            }
          >
            <Menu.Item
              disabled={!allowClicking}
              key='agreement-format'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/agreement-format' className='nav-text'>
                {/* <FileTextOutlined />
              <span>Agreement Format</span> */}
                <div
                  className={`${
                    pathname === '/agreement-format'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Agreement Format</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              disabled={!allowClicking}
              key='templates-format'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/templates-format' className='nav-text'>
                {/* <FileTextOutlined />
              <span>Templates Format</span> */}
                <div
                  className={`${
                    pathname === '/templates-format'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Templates Format</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              disabled={!allowClicking}
              key='ticket-mail-format'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/ticket-mail-format' className='nav-text'>
                {/* <FileTextOutlined />
              <span>Ticket Mail Format</span> */}
                <div
                  className={`${
                    pathname === '/ticket-mail-format'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Ticket Mail Format</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              disabled={!allowClicking}
              key='amc-mail-format'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/amc-mail-format' className='nav-text'>
                {/* <FileTextOutlined />
              <span>AMC Mail Format</span> */}
                <div
                  className={`${
                    pathname === '/amc-mail-format'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>AMC Mail Format</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              disabled={!allowClicking}
              key='messages'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/messages' className='nav-text'>
                {/* <MessageOutlined />
              <span>Messages</span> */}
                <div
                  className={`${
                    pathname === '/messages'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Messages</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {(isAdmin || currentReport.includes('view-sip')) && (
          <SubMenu
            key='More'
            className='custom-sub-menu'
            title={
              <Fragment>
                {/* <FileAddOutlined /> */}
                <img
                  src={
                    pathname.includes('/family-groups') ||
                    pathname.includes('/sip') ||
                    pathname.includes('/flags') ||
                    pathname.includes('/groups') ||
                    pathname.includes('/login-images') ||
                    pathname.includes('/popular-blogs') ||
                    pathname.includes('/company-summary')
                      ? InvoiceIconActive
                      : InvoiceIcon
                  }
                  alt='UserIcon'
                  style={{ marginRight: '12px' }}
                />
                <span>More</span>
              </Fragment>
            }
          >
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='family-groups'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/family-groups' className='nav-text'>
                  {/* <UsergroupDeleteOutlined />
                <span>Family Groups</span> */}
                  <div
                    className={`${
                      pathname === '/family-groups'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Family Groups</span>
                </NavLink>
              </Menu.Item>
            )}

            {currentReport.includes('view-sip') && (
              <Menu.Item
                disabled={!allowClicking}
                key='sip'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/sip' className='nav-text'>
                  {/* <CalendarOutlined />
                <span>SIP</span> */}
                  <div
                    className={`${
                      pathname === '/sip'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>SIP</span>
                </NavLink>
              </Menu.Item>
            )}
            {(isAdmin || isStaff) && (
              <Menu.Item
                disabled={!allowClicking}
                key='flags'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/flags' className='nav-text'>
                  {/* <FlagOutlined />
                <span>Flags</span> */}
                  <div
                    className={`${
                      pathname === '/flags'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Flags</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='groups'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/groups' className='nav-text'>
                  {/* <ApartmentOutlined />
                <span>Groups</span> */}
                  <div
                    className={`${
                      pathname === '/groups'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Groups</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='login-images'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/login-images' className='nav-text'>
                  {/* <FileImageOutlined />
                <span>Login Images</span> */}
                  <div
                    className={`${
                      pathname === '/login-images'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Login Images</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='popular-blogs'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/popular-blogs' className='nav-text'>
                  {/* <FileImageOutlined /> */}
                  <div
                    className={`${
                      pathname === '/popular-blogs'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Popular Blogs</span>
                </NavLink>
              </Menu.Item>
            )}

            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='company-summary'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/company-summary' className='nav-text'>
                  {/* <FileImageOutlined /> */}
                  <div
                    className={`${
                      pathname === '/company-summary'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Company Summary</span>
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {isAdmin && (
          <SubMenu
            key='plans'
            className='custom-sub-menu'
            title={
              <Fragment>
                {/* <SettingOutlined /> */}
                <img
                  src={
                    pathname.includes('/plans') ||
                    pathname.includes('/questionnaires') ||
                    pathname.includes('/report-permission') ||
                    pathname.includes('/reminder') ||
                    pathname.includes('/maintain') ||
                    pathname.includes('/client-fields') ||
                    pathname.includes('/alert-notification') ||
                    pathname.includes('/analysis-expiry-date') ||
                    pathname.includes('/faq-questions') ||
                    pathname.includes('/nse-holiday') ||
                    pathname.includes('/advisory-working-time') ||
                    pathname.includes('/risk-profile-score-setting') ||
                    pathname.includes('/pay-now-button-setting')
                      ? SettingIconActive
                      : SettingIcon
                  }
                  alt='SettingIcon'
                  style={{ marginRight: '12px' }}
                />
                <span>Setting</span>
              </Fragment>
            }
          >
            <Menu.Item
              disabled={!allowClicking}
              key='plans'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/plans' className='nav-text'>
                {/* <FormOutlined />
              <span>Plans</span> */}
                <div
                  className={`${
                    pathname === '/plans'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Plans</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              disabled={!allowClicking}
              key='questionnaires'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/questionnaires' className='nav-text'>
                {/* <QuestionOutlined />
              <span>Questionnaires</span> */}
                <div
                  className={`${
                    pathname === '/questionnaires'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Questionnaires</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              disabled={!allowClicking}
              key='report-permission'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/report-permission' className='nav-text'>
                {/* <SettingOutlined />
              <span>Report Permission</span> */}
                <div
                  className={`${
                    pathname === '/report-permission'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Report Permission</span>
              </NavLink>
            </Menu.Item>
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='reminder'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/reminder' className='nav-text'>
                  {/* <BellOutlined />
                <span>Amc Reminder</span> */}
                  <div
                    className={`${
                      pathname === '/reminder'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Amc Reminder</span>
                </NavLink>
              </Menu.Item>
            )}
            <Menu.Item
              disabled={!allowClicking}
              key='maintain'
              onClick={() => setVisibleDrawer(isMobile ? true : false)}
            >
              <NavLink to='/maintain' className='nav-text'>
                {/* <SettingOutlined />
              <span>Maintain</span> */}
                <div
                  className={`${
                    pathname === '/maintain'
                      ? 'sub-menu-icon-active'
                      : 'sub-menu-icon'
                  }`}
                ></div>{' '}
                <span style={{ marginLeft: '12px' }}>Maintain</span>
              </NavLink>
            </Menu.Item>
            {isAdmin && (
              <Menu.Item disabled={!allowClicking} key='client-fields'>
                <NavLink to='/client-fields' className='nav-text'>
                  {/* <SelectOutlined />
                <span>Client Fields</span> */}
                  <div
                    className={`${
                      pathname === '/client-fields'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Client Fields</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='alert-notification'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/alert-notification' className='nav-text'>
                  {/* <SettingOutlined />
                <span>Alert Notification</span> */}
                  <div
                    className={`${
                      pathname === '/alert-notification'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Alert Notification</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='analysis-expiry-date'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/analysis-expiry-date' className='nav-text'>
                  {/* <CalendarOutlined />
                <span>Analysis Expiry Date</span> */}
                  <div
                    className={`${
                      pathname === '/analysis-expiry-date'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>
                    Analysis Expiry Date
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='faq-questions'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/faq-questions' className='nav-text'>
                  {/* <QuestionOutlined />
                <span>FAQs</span> */}
                  <div
                    className={`${
                      pathname === '/faq-questions'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>FAQ</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='nse-holiday'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/nse-holiday' className='nav-text'>
                  {/* <CalendarOutlined />
                <span>NSE Holiday</span> */}
                  <div
                    className={`${
                      pathname === '/nse-holiday'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Nse Holiday</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='advisory-working-time'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/advisory-working-time' className='nav-text'>
                  {/* <CalendarOutlined />
                <span>Advisory Working Time</span> */}
                  <div
                    className={`${
                      pathname === '/advisory-working-time'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>
                    Advisory Working Time
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='risk-profile-score-setting'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/risk-profile-score-setting' className='nav-text'>
                  {/* <CalendarOutlined />
                <span>Risk Profile Score</span> */}
                  <div
                    className={`${
                      pathname === '/risk-profile-score-setting'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>Risk Profile Score</span>
                </NavLink>
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                disabled={!allowClicking}
                key='pay-now-button-setting'
                onClick={() => setVisibleDrawer(isMobile ? true : false)}
              >
                <NavLink to='/pay-now-button-setting' className='nav-text'>
                  {/* <CalendarOutlined />
                <span>Risk Profile Score</span> */}
                  <div
                    className={`${
                      pathname === '/pay-now-button-setting'
                        ? 'sub-menu-icon-active'
                        : 'sub-menu-icon'
                    }`}
                  ></div>{' '}
                  <span style={{ marginLeft: '12px' }}>
                    Pay now button setting
                  </span>
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {isAdmin && (
          <Menu.Item
            disabled={!allowClicking}
            key='schedule-notifications'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/schedule-notifications' className='nav-text'>
              <NotificationOutlined />
              <span>Notifications</span>
            </NavLink>
          </Menu.Item>
        )}
        {(!isAdmin || isStaff) && (
          <Menu.Item
            disabled={!allowClicking}
            key='support'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/support' className='nav-text'>
              {/* <CustomerServiceOutlined /> */}
              <img
                src={
                  pathname.includes('/support')
                    ? SupportIconActive
                    : SupportIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span>Support</span>
            </NavLink>
          </Menu.Item>
        )}

        {isClient && currentUser.completedSteps === 'DONE' && (
          <Menu.Item
            disabled={!allowClicking}
            key='reevalute'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/reevalute' className='nav-text'>
              {/* <RetweetOutlined /> */}
              <img
                src={
                  pathname.includes('/reevalute')
                    ? ReEvaluateIconActive
                    : ReEvaluateIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span>Re-evaluate</span>
            </NavLink>
          </Menu.Item>
        )}

        {isClient && (
          <Menu.Item
            disabled={!allowClicking}
            key='pending-advisory'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/pending-advisory' className='nav-text'>
              {/* <ExceptionOutlined /> */}
              <img
                src={
                  pathname.includes('/pending-advisory')
                    ? ReportIconActive
                    : ReportIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span>
                Pending Advisory{' '}
                {advisoryType?.length > 0 ? `(${advisoryType?.length})` : ''}{' '}
              </span>
            </NavLink>
          </Menu.Item>
        )}
        {isAdmin && (
          <Menu.Item
            disabled={!allowClicking}
            key='book-advisory'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/book-advisory' className='nav-text'>
              {/* <ProfileOutlined /> */}
              <img
                src={
                  pathname.includes('/book-advisory')
                    ? ReportIconActive
                    : ReportIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span>Auto Advisory</span>
            </NavLink>
          </Menu.Item>
        )}
        {isClient && currentUser?.isShowAddAua && (
          <Menu.Item
            disabled={!allowClicking}
            key='add-aua-verification'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/add-aua-verification' className='nav-text'>
              {/* <ProfileOutlined /> */}
              <img
                src={
                  pathname.includes('/add-aua-verification')
                    ? AUAIconActive
                    : AUAIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span>Add AUA</span>
            </NavLink>
          </Menu.Item>
        )}

        {!isAdmin && !isClient && (
          <Menu.Item
            disabled={!allowClicking}
            key='pending-advisory-for-associate'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/pending-advisory-for-associate' className='nav-text'>
              {/* <ProfileOutlined /> */}
              <img
                src={
                  pathname.includes('/pending-advisory-for-associate')
                    ? ReportIconActive
                    : ReportIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span>
                Pending Advisory{' '}
                {pendingAdvisory?.getPendingAdvisoryForAssociate?.count
                  ? `(${pendingAdvisory?.getPendingAdvisoryForAssociate?.count})`
                  : ''}
              </span>
            </NavLink>
          </Menu.Item>
        )}
        {isClient && (
          <Menu.Item
            disabled={!allowClicking}
            key='update-client-position'
            onClick={() => setVisibleDrawer(isMobile ? true : false)}
          >
            <NavLink to='/update-client-position' className='nav-text'>
              <img
                src={
                  pathname.includes('/update-client-position')
                    ? ReportIconActive
                    : ReportIcon
                }
                alt='ReportIcon'
                style={{ marginRight: '12px' }}
              />{' '}
              <span>Update Position</span>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    </>
  )
}
