import gql from 'graphql-tag'

export const GET_ANALYSIS_EXPIRY_DATE = gql`
  query GetAnalysisExpiryDate {
    getAnalysisExpiryDate {
      id
      expiryDate
      createdAt
      updatedAt
    }
  }
`
export const GET_SP_ANALYSIS_EXPIRY_DATE = gql`
  query Query {
    getSPExpiryDate
  }
`
