import { useQuery } from '@apollo/react-hooks'
import { Button, DatePicker, Input, Select, Space, Table } from 'antd'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { amcStatus, getName } from 'utils/User'
import { GET_ALL_DUE_AMCS } from '../graphql/Queries'
import '../index.css'
import {
  SearchOutlined,
  LinkOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import _isEmpty from 'lodash/isEmpty'
import states from 'utils/states.json'
import client from 'apollo'
import { GET_DUE_AMC } from 'modules/Users/graphql/Queries'
import { get } from 'lodash'
import openNotification from 'utils/Notification'

const { RangePicker } = DatePicker
const { Option } = Select

const selectedTypeDate = [
  { text: 'YES', value: 'true' },
  { text: 'NO', value: 'false' },
]

const DueAmc = () => {
  const { state } = useContext(AppContext)
  const {
    isClient,
    isAdmin,
    currentUser: { id, email },
  } = state
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState(
    isClient ? { clientId: parseInt(id).toString() } : { status: 'PENDING' }
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [CSVLoading, setCSVLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  // const [selectedData, setSelectedData] = useState([])
  // const [selectAllInvoiceForBulk, setSelectAllInvoiceForBulk] = useState(false)
  // const [loadingForBulk, setLoading] = useState(false)
  // const [allBulkData, setAllBulkData] = useState([])

  let variable = {
    where: { ...filters, isBulkInvoicePage: false, isPending: true },
    sort,
    skip,
    limit,
  }
  if (filters.associateName) {
    delete variable.skip
    delete variable.limit
  }

  const { data, loading, error } = useQuery(GET_ALL_DUE_AMCS, {
    variables: { ...variable, isBulkInvoicePage: false },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (
      skip === 0 &&
      limit === data?.getAllDueAmcs?.count &&
      !loading &&
      _isEmpty(selectedRow)
    ) {
      let newIds = data?.getAllDueAmcs?.amc?.map((amc) => amc.id)
      setSelectedRow(newIds)
      // setSelectedData(data?.getAllDueAmcs?.amc)
    }
  }, [skip, limit, loading, data, selectedRow])

  const getData = (amcData) => {
    let data = amcData
    data = data.map((amc) => {
      let amcObj = amc

      let count = 1
      const getRole = (amcData) => {
        if (
          (amcData?.role === 'MASTER_ASSOCIATE' ||
            amcData?.role === 'ASSOCIATE') &&
          count === 1
        ) {
          amcObj.associateName = amcData?.firstName + ' ' + amcData?.lastName
          amcObj.associateEmail = amcData?.email
          amcObj.associatePhone = amcData?.phone
          amcObj.associateId = amcData?.id
        } else if (amcData?.role === 'SALES_EXECUTIVE') {
          amcObj.rmName = amcData?.firstName + ' ' + amcData?.lastName
          amcObj.rmEmail = amcData?.email
          amcObj.rmPhone = amcData?.phone
          amcObj.rmId = amcData?.id
        }

        if (amcData?.createdBy) {
          count += 1
          getRole(amcData?.createdBy)
        }
      }
      getRole(amc?.client?.createdBy)
      return { ...amc, ...amcObj }
    })
    return data
  }

  if (!loading && get(data, 'getAllDueAmcs.amc')) {
    data.getAllDueAmcs.amc = getData(data?.getAllDueAmcs?.amc)
  }

  // useEffect(() => {
  //   if (selectAllInvoiceForBulk) {
  //     setLoading(true)
  //     const variables = {
  //       ...variable,
  //       limit: 500000,
  //     }
  //     client
  //       .query({
  //         query: GET_ALL_DUE_AMCS,
  //         variables: variables,
  //         fetchPolicy: 'network-only',
  //       })
  //       .then((res) => {
  //         setSelectedData(res.data.getAllDueAmcs.amc)
  //         setAllBulkData(res.data.getAllDueAmcs.amc)
  //         setLoading(false)
  //       })
  //       .catch((err) => {
  //         setLoading(false)
  //       })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectAllInvoiceForBulk])

  // useEffect(() => {
  //   if (allBulkData.length) {
  //     setSelectedRow(
  //       allBulkData.map((item) => {
  //         return item.id
  //       })
  //     )
  //   }

  //   if (selectedData.length) {
  //     setSelectedRow(
  //       selectedData.map((item) => {
  //         return item.id
  //       })
  //     )
  //   }
  // }, [selectedData, allBulkData])

  if (error) return <Page404 error={error} />

  let totalCount = data?.getAllDueAmcs?.count

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    }

    if (filter.amcStatusType) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.amcStatusType
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.eMandateStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['deduction'] = filter.eMandateStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.amcGeneratedAt) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['amcGeneratedAt'] = filter.amcGeneratedAt
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = 'PENDING'
      // delete tempFilters.status
      tempFilters['amcGeneratedAt'] = ''
      delete tempFilters.amcGeneratedAt
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus={true}
            value={selectedKeys[0]}
            placeholder={`Search ${dataIndex}`}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
          />
          <Space>
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, dataIndex)}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleCsvDownload() {
    openNotification(
      'success',
      `You will receive an email shortly on ${email}.`
    )
    setCSVLoading(false)
    client
      .query({
        query: GET_DUE_AMC,
        variables: {
          where: { ...filters, isExport: true },
          skip: 0,
          sort,
          limit: totalCount,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLimit(10)
        setSkip(0)
        setCSVLoading(false)
      })
      .catch((err) => {
        setLimit(10)
        setSkip(0)
        setCSVLoading(false)

        console.log(err)
      })
      .finally(() => {
        setLimit(10)

        setSkip(0)
        setCSVLoading(false)
      })
  }

  // const rowSelection = {
  //   selectedRowKeys: selectedRow,
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectAllInvoiceForBulk(false)
  //     setSelectedData(selectedRows)
  //     setSelectedRow(selectedRowKeys)
  //   },
  //   onSelectAll: (record) => {
  //     setSelectAllInvoiceForBulk(true)
  //   },

  //   onSelect: (record) => {
  //     const isTrue = selectedRow.findIndex((i) => i === record.id)
  //     if (isTrue > -1) {
  //       const newValues = selectedRow.filter((i) => i !== record.id)
  //       setSelectedRow(newValues)
  //     } else {
  //       setSelectedRow((oldData) => [...oldData, record.id])
  //       // setSelectedData((oldData) => [...oldData, record])
  //     }
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === 'Disabled User',
  //     name: record.name,
  //   }),
  // }

  const adminFields = [
    'clientEmail',
    'clientPhone',
    'associateEmailId',
    'associateMobileNo',
    'amcGeneratedAt',
    'rmEmailId',
    'rmMobileNo',
    'paidAtDate',
    'currentAUA',
    'amcInPer',
    'amcStartDate',
    'amcEndDate',
    'dueDays',
    'commission',
    'totalGrossValue',
    'gst18InPer',
    'finalNetAmount',
    'quantity',
    'clientAddress',
    'state',
    'nextDueDate',
    'createdAt',
    'discount',
  ]
  const columns = [
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      width: 150,
      sorter: (a, b) => a.clientCode - b.clientCode,
      render: (text, record) => (
        <NavLink to={`/clients/${parseInt(record?.client?.id)} `}>
          {record?.client?.clientCode || '-'}
        </NavLink>
      ),
      ...getColumnSearchProps('clientCodeSearch'),
    },
    {
      title: 'Client Name',
      dataIndex: 'client',
      key: 'client',
      width: 150,
      render: (client) => (client === 'Total' ? 'Total' : getName(client)),
      sorter: (a, b) => a.client - b.client,
      ...getColumnSearchProps('clientNameSearch'),
    },
    {
      title: 'Client Email',
      dataIndex: 'client',
      key: 'clientEmail',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/clients/${record?.client?.id} `}>
          {record?.client?.email || '-'}
        </NavLink>
      ),
      sorter: (a, b) => a.client.email - b.client.email,
      ...getColumnSearchProps('clientEmailSearch'),
    },

    {
      title: 'Client Mobile No',
      dataIndex: 'client',
      key: 'clientPhone',
      width: 150,
      render: (text, record) => record?.client?.phone || '-',
      sorter: (a, b) => a.client.phone - b.client.phone,
    },
    {
      title: 'Associate Name',
      dataIndex: 'associateName',
      key: 'associateName',
      width: 150,
      render: (text, record) =>
        (
          <NavLink to={`/users/${record?.associateId} `}>
            {record?.associateName ? record?.associateName : '-'}
            {/* {record?.client?.createdBy
              ? `${record?.client?.createdBy?.firstName} ${record?.client?.createdBy?.lastName} `
              : '-'} */}
          </NavLink>
        ) || '-',
      sorter: (a, b) => a.associateName - b.associateName,
      ...getColumnSearchProps('associateName'),
    },
    {
      title: 'Associate Email id',
      dataIndex: 'client',
      key: 'associateEmailId',
      width: 150,

      render: (text, record) => record?.associateEmail || '-',
      sorter: (a, b) => a.associateEmail - b.associateEmail,
      ...getColumnSearchProps('associateEmail'),
    },
    {
      title: 'Associate Mobile No',
      dataIndex: 'client',
      key: 'associateMobileNo',
      width: 150,
      render: (text, record) => record?.associatePhone || '-',
      sorter: (a, b) => a.associatePhone - b.associatePhone,
    },
    {
      title: 'Amc Generated At',
      dataIndex: 'amcGeneratedAt',
      key: 'amcGeneratedAt',
      width: 150,
      render: (text, record) =>
        (record?.amcGeneratedAt &&
          moment(record?.amcGeneratedAt).format('Do MMM YYYY')) ||
        '-',
      sorter: (a, b) => console.log(a, b),
      filters: selectedTypeDate,
    },
    {
      title: 'RM Name',
      dataIndex: 'client',
      key: 'rmName',
      width: 150,
      render: (text, record) =>
        (
          <NavLink to={`/users/${record?.rmId} `}>
            {record?.rmName ? record?.rmName : '-'}
            {/* {record?.client?.createdBy?.createdBy
              ? `${record?.client?.createdBy?.createdBy?.firstName} ${record?.client?.createdBy?.createdBy?.lastName}`
              : '-'} */}
          </NavLink>
        ) || '-',
      sorter: (a, b) => a.rmName - b.rmName,
    },
    {
      title: 'RM Email id',
      dataIndex: 'client',
      key: 'rmEmailId',
      width: 150,
      render: (text, record) => record?.rmEmail || '-',
      sorter: (a, b) => a.rmEmail - b.rmEmail,
      filters: '',
    },
    {
      title: 'RM Mobile No',
      dataIndex: 'client',
      key: 'rmMobileNo',
      width: 150,
      render: (text, record) => record?.rmPhone || '-',
      sorter: (a, b) => a.rmPhone - b.rmPhone,
      filters: '',
    },
    {
      title: 'Amc Status Type',
      dataIndex: 'amcStatusType',
      key: 'amcStatusType',
      width: 150,
      render: (text, record) => record?.status,
      filters: amcStatus,
    },
    {
      title: 'Next due Date',
      dataIndex: 'nextDueDate',
      key: 'nextDueDate',
      width: 150,
      render: (text, record) =>
        record?.nextDueDate
          ? moment(record?.nextDueDate).format('Do MMM YYYY')
          : '-',
      sorter: (a, b) => a.nextDueDate - b.nextDueDate,
      ...getColumnDateProps('nextDueDate'),
    },
    {
      title: 'Paid At Date',
      dataIndex: 'paidAt',
      key: 'paidAtDate',
      width: 150,
      render: (text, record) =>
        record?.paidAt ? moment(record?.paidAt).format('Do MMM YYYY') : '-',
      sorter: (a, b) => a.paidAtDate - b.paidAtDate,
      ...getColumnDateProps('paidAt'),
    },
    {
      title: 'Current AUA',
      dataIndex: 'client',
      key: 'currentAUA',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.assetUnderAdvisory) || '-'}
            {record?.nextAmc?.totalExposure && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.totalExposure})
              </p>
            )}
          </div>
        )
      },
      sorter: (a, b) =>
        a.invoice?.assetUnderAdvisory - b.invoice?.assetUnderAdvisory,
    },
    {
      title: 'Amc %',
      dataIndex: 'amcInPer',
      key: 'amcInPer',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record?.invoice?.amcInPer || '-'}
            {record?.nextAmc?.amcInPer && (
              <p style={{ color: 'orange' }}>({record?.nextAmc?.amcInPer})</p>
            )}
          </div>
        )
      },
    },
    {
      title: 'created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      render: (text, record) =>
        record.createdAt
          ? moment(record.createdAt).format('DD MMM YYYY, h:mm:ss a')
          : '-',
      sorter: (a, b) => a.createdAt - b.createdAt,
      ...getColumnDateProps('createdAt'),
    },
    {
      title: 'Amc Start Date',
      dataIndex: 'startDate',
      key: 'amcStartDate',
      width: 150,
      render: (text, record) =>
        moment(record?.startDate).format('Do MMM YYYY') || '-',
      sorter: (a, b) => a.amcStartDate - b.amcStartDate,
      ...getColumnDateProps('startDate'),
    },
    {
      title: 'Amc End Date',
      dataIndex: 'endDate',
      key: 'amcEndDate',
      width: 150,
      render: (text, record) =>
        moment(record?.endDate).format('Do MMM YYYY') || '-',
      sorter: (a, b) => a.amcEndDate - b.amcEndDate,
      ...getColumnDateProps('endDate'),
    },
    {
      title: 'Due Days',
      dataIndex: 'dueDays',
      key: 'dueDays',
      width: 150,
      render: (text, record) =>
        `${
          moment(record?.endDate, 'YYYY-MM-DD').diff(
            moment(record?.startDate, 'YYYY-MM-DD'),
            'days'
          ) + 1
        } Days`,
    },
    {
      title: 'Discount %',
      dataIndex: 'discount',
      key: 'discount',
      width: 150,
      render: (text, record) =>
        Math.round(record?.invoice?.discountInPer) || '-',
    },
    {
      title: 'Commission %',
      dataIndex: 'commission',
      key: 'commission',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.commissionInPer) || '-'}
            {record?.nextAmc?.commissionInPer > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.commissionInPer})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Total Gross Value',
      dataIndex: 'totalGrossValue',
      key: 'totalGrossValue',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.grossTotalAmount) || '-'}
            {record?.nextAmc?.grossTotalAmount > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.grossTotalAmount})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'GST 18%',
      dataIndex: 'gst18InPer',
      key: 'gst18InPer',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.igst18InPer) || '-'}
            {record?.nextAmc?.igst18InPer > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.igst18InPer})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Final Net Amount',
      dataIndex: 'finalNetAmount',
      key: 'finalNetAmount',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.totalNetAmount) || '-'}
            {record?.nextAmc?.totalNetAmount > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.totalNetAmount})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (text, record) => record?.amcQty || '-',
    },
    {
      title: 'Amc period',
      dataIndex: 'amcPeriod',
      key: 'amcPeriod',
      width: 150,
      render: (text, record) =>
        `${moment(record?.startDate).format('DD-MMM-YYYY')} to ${moment(
          record?.endDate
        ).format('DD-MMM-YYYY')}` || '-',
    },
    {
      title: 'Client Address',
      dataIndex: 'clientAddress',
      key: 'clientAddress',
      width: 150,
      render: (text, record) => record?.client?.address || '-',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 150,
      render: (text, record) =>
        states.filter((item) => item.id === +record?.client?.stateId)[0]?.name,
    },
    {
      title: 'E mandate Status',
      dataIndex: 'eMandateStatus',
      key: 'eMandateStatus',
      width: 150,
      render: (text, record) =>
        record.client.umrnNumber !== null && record.client.umrnNumber
          ? 'E_MANDATE'
          : 'NON_E_MANDATE',
    },
    {
      title: 'Invoice Pdf',
      key: 'invoicePdf',
      dataIndex: 'invoicePdf',
      width: 150,
      render: (text, record) => (
        <div style={{ textAlign: 'center' }}>
          {record?.invoice?.invoicePdf ? (
            <a
              href={`${record?.invoice?.invoicePdf}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkOutlined />
            </a>
          ) : (
            '-'
          )}
        </div>
      ),
    },
  ]
  const filteredColumns = columns?.filter((column) => {
    if (!isAdmin) return !adminFields?.includes(column?.key)
    return true
  })
  return (
    <>
      <CustomMeta title='Invoice' />
      {/* <PageHeader
        className='box'
        title='Amc Due Report'
        extra={[
          <>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Select
              getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Amount'
                style={{
                  width: '100px',
                  textAlign: 'center',
                  display: 'block',
                }}
                defaultValue='all'
                onChange={(e) => {
                  setFilters(() => {
                    return { ...filters, isActiveUser: e }
                  })
                }}
              >
                <Option value='all'>All</Option>
                <Option value='true'>Active</Option>
                <Option value='false'>DeActive</Option>
              </Select>
              <Button
                key='1'
                type='primary'
                onClick={handleCsvDownload}
                loading={CSVLoading}
              >
                Export Csv
              </Button>
            </div>
          </>,
        ]}
      /> */}
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>Amc Due Report</div>
          <div className='container_Export_Popover'>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              style={{ width: '100px' }}
              className='display-field'
              defaultValue='all'
              onChange={(e) => {
                setFilters(() => {
                  return { ...filters, isActiveUser: e }
                })
                setCurrentPage(1)
                setSkip()
              }}
            >
              <Option value='all'>All</Option>
              <Option value='true'>Active</Option>
              <Option value='false'>DeActive</Option>
            </Select>
            <Button
              disabled={CSVLoading || !data?.getAllDueAmcs?.amc?.length}
              loading={CSVLoading}
              onClick={handleCsvDownload}
            >
              Export{' '}
              <DownloadOutlined color='#AF0000' style={{ fontSize: '16px' }} />
            </Button>
          </div>
        </div>
        <Table
          // rowSelection={{
          //   type: 'checkbox',
          //   ...rowSelection,
          // }}
          loading={loading}
          rowKey={(record) => record.id}
          columns={filteredColumns}
          dataSource={data && data.getAllDueAmcs.amc}
          onChange={handleTableChange}
          scroll={{ x: true, y: true }}
          pagination={{
            pageSize: limit,
            total: data?.getAllDueAmcs?.count,
            onChange: handlePagination,
            current: currentPage,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
      </div>
    </>
  )
}

export default DueAmc
