import {
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Upload,
  InputNumber,
  Switch,
} from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import _find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'
import './index.css'

import {
  GET_MEMBER_NAME,
  GET_LEAD_USERS,
  GET_SYSTEM_USERS,
  // USER_PUBLIC,
} from 'modules/Users/graphql/Queries'
import { GET_PLANS } from 'modules/Plans/graphql/Query'
import countries from 'utils/countries.json'
import { AppContext } from 'AppContext'
import states from 'utils/states.json'
import cities from 'utils/cities.json'
import { getName } from 'utils/User'
import client from 'apollo'
import '../clients.css'
import Loader from 'components/loaders/Loader'
import { handleInputChange, handlePanCard } from 'utils/utilities'
// import { uniqBy } from 'lodash'

const { Option } = Select
const defaultSteps = [
  { key: 'SELECT_USER_TYPE', value: 'SELECT_USER_TYPE' },
  { key: 'LOGIN_CREATED', value: 'LOGIN_CREATED' },
  { key: 'CLIENT_PROFILE_1', value: 'CLIENT_PROFILE_1' },
  { key: 'CLIENT_PROFILE_2', value: 'CLIENT_PROFILE_2' },
  { key: 'RISK_PROFILE', value: 'RISK_PROFILE' },
  { key: 'SUMMARY', value: 'SUMMARY' },
  { key: 'KYC_DOC', value: 'KYC_DOC' },
  { key: 'KYC_FORM', value: 'KYC_FORM' },
  { key: 'INVESTMENT_PLANNING', value: 'INVESTMENT_PLANNING' },
  { key: 'AGREEMENT_SIGN', value: 'AGREEMENT_SIGN' },
  { key: 'COURIER_INSTRUCTION', value: 'COURIER_INSTRUCTION' },
  { key: 'E_MANDATE', value: 'E_MANDATE' },
  { key: 'CONGRATULATION', value: 'CONGRATULATION' },
  { key: 'DONE', value: 'DONE' },
]

// const formItemLayout = {
//   labelCol: { xs: { span: 24 }, sm: { span: 24 } },
//   wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
// }

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } },
}

function disabledDate(current) {
  return current && current > moment().endOf('day')
}

const ClientForm = (props) => {
  const {
    state: { isStaff, isAdmin, isClient, isMobile },
  } = useContext(AppContext) // currentUser
  const { onFinish, loading, isUpdate } = props
  let { initialValues } = props
  const { userData } = props
  const [selectedState, setSelectedState] = useState(
    isUpdate && initialValues.stateId
      ? states.filter((state) => state.id === initialValues.stateId)[0].name
      : undefined
  )
  const [selectedCountry, setSelectedCountry] = useState(
    isUpdate && initialValues.countryId
      ? countries.filter((country) => country.id === initialValues.countryId)[0]
          .name
      : undefined
  )
  const [fileList, setFileList] = useState([])
  const [users, setUsers] = useState(undefined)
  const [members, setMembers] = useState(undefined)
  const [plans, setPlans] = useState([])
  const [leadUsers, setLeadUsers] = useState(undefined)
  // const [disableChecked, setDisableChecked] = useState(
  //   !userData?.getUser?.isActive
  // )
  const [reUploadKyc, setReUploadKyc] = useState(
    (initialValues && initialValues?.isReUploadKyc) || false
  )
  const [selectedUser, setSelectedUser] = useState(
    (initialValues && initialValues.createdById) || undefined
  )
  const [commissionSelectUser, setCommissionSelectUser] = useState(
    (initialValues && initialValues.commissionSelectUser) || undefined
  )
  const [form] = Form.useForm()
  const { data: memberData, loading: memberLoading } = useQuery(
    GET_MEMBER_NAME,
    { fetchPolicy: 'network-only' }
  )
  const { data: getPlans, loading: planLoading } = useQuery(GET_PLANS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!memberLoading && get(memberData, 'getMemberName')) {
      setMembers(get(memberData, 'getMemberName'))
    }
  }, [memberData, memberLoading])

  useEffect(() => {
    if (!planLoading && get(getPlans, 'getPlans')) {
      setPlans(get(getPlans, 'getPlans'))
    }
  }, [getPlans, planLoading])

  useEffect(() => {
    client
      .query({
        query: GET_SYSTEM_USERS,
        variables: {
          limit: 500,
          where: {},
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (get(res, 'data.getSystemUsers.users')) {
          setUsers(get(res, 'data.getSystemUsers.users'))
        }
      })
      .catch((err) => {
        console.log(err)
        setUsers([])
      })
  }, [isAdmin, isStaff])

  // const [otherMail, setOtherMail] = useState(
  //   initialValues?.otherMailIds
  //     ? initialValues?.otherMailIds?.map((email, key) => {
  //         return {
  //           key,
  //           label: email,
  //           value: email,
  //         }
  //       })
  //     : []
  // )

  // console.log(otherMail)

  // let userEmails = []
  // const { data: userData, loading: userLoading } = useQuery(USER_PUBLIC, {
  //   fetchPolicy: 'network-only',
  // })

  // if (!userLoading && get(userData, 'getUsersPublic.users')) {
  //   userData.getUsersPublic.users?.length &&
  //     userData.getUsersPublic.users.forEach((item, key) => {
  //       userEmails.push({
  //         key,
  //         label: item?.email,
  //         value: item?.email,
  //       })
  //     })
  // }

  // const handleEditInputChange = (e) => {
  //   const array = e.map((email) => {
  //     return {
  //       email,
  //     }
  //   })

  //   setOtherMail(array)
  // }

  useEffect(() => {
    setLeadUsers(undefined)
    if (selectedUser && users) {
      function getLeadUsers(userId) {
        client
          .query({
            query: GET_LEAD_USERS,
            variables: { userId },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            if (get(res, 'data.getLeadUsers')) {
              let find = []
              if (users) {
                find = _find(users, (user) => user.id === selectedUser)
                setLeadUsers([
                  {
                    id: find.id,
                    role: find.role,
                    name: getName(find),
                    username: find.userName,
                    level: get(res, 'data.getLeadUsers.length'),
                  },
                  ...get(res, 'data.getLeadUsers'),
                ])
              } else {
                setLeadUsers(get(res, 'data.getLeadUsers'))
              }
            } else {
              setLeadUsers(undefined)
            }
          })
          .catch((err) => setLeadUsers(undefined))
      }
      getLeadUsers(parseInt(selectedUser))
    }
  }, [selectedUser, users])

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleRemove = () => {
    setFileList([])
  }

  const handleChange = (info) => {
    if (info.fileList.length > 1) {
      info.fileList.shift()
    }
    setFileList(info.fileList)
  }
  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  initialValues = {
    ...initialValues,
    plan: initialValues?.plan?.id || null,
    collateralValue: initialValues?.collateralValue || '0',
    panCard: initialValues?.kyc?.panCard
      ? [
          {
            uid: 1,
            name: initialValues?.kyc?.panCard
              ?.split('/')
              [initialValues?.kyc?.panCard?.split('/')?.length - 1].replaceAll(
                '%20',
                ' '
              ),
            status: 'done',
            url: initialValues?.kyc?.panCard,
          },
        ]
      : [],
    signature: initialValues?.kyc?.signature
      ? [
          {
            uid: 1,
            name: initialValues?.kyc?.signature
              ?.split('/')
              [
                initialValues?.kyc?.signature?.split('/')?.length - 1
              ].replaceAll('%20', ' '),
            status: 'done',
            url: initialValues?.kyc?.signature,
          },
        ]
      : [],
    aadharCard: initialValues?.kyc?.aadharCard
      ? [
          {
            uid: 1,
            name: initialValues?.kyc?.aadharCard
              ?.split('/')
              [
                initialValues?.kyc?.aadharCard?.split('/')?.length - 1
              ].replaceAll('%20', ' '),
            status: 'done',
            url: initialValues?.kyc?.aadharCard,
          },
        ]
      : [],
    passportSize: initialValues?.kyc?.passportSize
      ? [
          {
            uid: 1,
            name: initialValues?.kyc?.passportSize
              ?.split('/')
              [
                initialValues?.kyc?.passportSize?.split('/')?.length - 1
              ].replaceAll('%20', ' '),
            status: 'done',
            url: initialValues?.kyc?.passportSize,
          },
        ]
      : [],
    bankStatement: initialValues?.kyc?.bankStatement
      ? [
          {
            uid: 1,
            name: initialValues?.kyc?.bankStatement
              ?.split('/')
              [
                initialValues?.kyc?.bankStatement?.split('/')?.length - 1
              ].replaceAll('%20', ' '),
            status: 'done',
            url: initialValues?.kyc?.bankStatement,
          },
        ]
      : [],

    cancelledChequeSnap: initialValues?.kyc?.cancelledChequeSnap
      ? initialValues.kyc.cancelledChequeSnap.map((snap, index) => ({
          uid: index + 1,
          name: snap
            ?.split('/')
            [snap?.split('/')?.length - 1].replaceAll('%20', ' '),
          status: 'done',
          url: snap,
        }))
      : [],
  }

  if (!users) return <Loader />
  return (
    <Form
      // {...formItemLayout}
      form={form}
      name='register'
      scrollToFirstError
      onFinish={onFinish}
      initialValues={initialValues || {}}
      layout='vertical'
      className='client-detail-form'
    >
      <Row gutter={16} className='client-profile-row'>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='firstName'
            label='First Name'
            rules={[
              { required: true, message: 'Please input your First Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter First Name'
              disabled={isClient}
              className='client-detail-input'
              onChange={(e) => handleInputChange(form, 'firstName', e)}
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='lastName'
            label='Last Name'
            rules={[
              { required: true, message: 'Please input your Last Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter Last Name'
              disabled={isClient}
              className='client-detail-input'
              onChange={(e) => handleInputChange(form, 'lastName', e)}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='userName'
            autoComplete='new-password'
            rules={[
              {
                required: true,
                message: 'Please input your valid username!',
              },
            ]}
            label={
              <span>
                Username&nbsp;{' '}
                <Tooltip title='Choose your unique username for login.'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input
              autoComplete='new-password'
              placeholder='Enter Username'
              disabled={isUpdate ? true : false}
              className='client-detail-input'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='email'
            label='E-mail'
            rules={[
              { required: true, message: 'Please input your E-mail!' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
                pattern: new RegExp(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
              },
            ]}
          >
            <Input
              className='client-detail-input'
              placeholder='Enter Your Email'
              disabled={isClient}
              style={{ textTransform: 'lowercase' }}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='panCardNo' label='Pan Card No'>
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              onChange={(e) => handlePanCard(form, 'panCardNo', e)}
              placeholder='Enter Pan Card No'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item label='Other Mail' name='otherMailIds'>
            <Input
              className='client-detail-input'
              allowClear
              style={{
                width: '100%',
                paddingLeft: '12px',
                borderRadius: '5px',
              }}
              placeholder='Please Select other Mail'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='phone'
            label='Phone Number'
            rules={[
              { required: true, message: 'Please input your phone number!' },
            ]}
          >
            <Input
              className='client-detail-input'
              autoComplete='off'
              type='number'
              style={{ width: '100%' }}
              placeholder='Enter Phone number'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='dateOfBirth'
            label='Date of Birth'
            rules={[
              { required: true, message: 'Please input your Date of Birth!' },
            ]}
          >
            <DatePicker
              className='client-detail-date-picker'
              getPopupContainer={(trigger) => trigger.parentElement}
              inputReadOnly
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              disabledDate={disabledDate}
              showToday={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        {!isUpdate && (
          <Fragment>
            <Col
              className='gutter-row client-column'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            >
              <Form.Item
                hasFeedback
                name='password'
                label='Password'
                autoComplete='new-password'
                rules={[
                  {
                    required: true,
                    message:
                      'Please input your password between 8 to 16 characters!',
                    min: 8,
                    max: 16,
                  },
                ]}
              >
                <Input.Password
                  className='client-detail-input'
                  placeholder='Enter Your Password'
                  autoComplete='new-password'
                  disabled={isClient}
                />
              </Form.Item>
            </Col>

            <Col
              className='gutter-row client-column'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            >
              <Form.Item
                hasFeedback
                name='confirm'
                label='Confirm Password'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      )
                    },
                  }),
                ]}
              >
                <Input.Password
                  className='client-detail-input'
                  placeholder='Enter Confirm Password'
                  disabled={isClient}
                />
              </Form.Item>
            </Col>
          </Fragment>
        )}

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='cvlReportStatus' label='CVL Status'>
            <Switch
              checked={initialValues?.cvlReportStatus === true ? true : false}
              disabled
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='suitabilityReportStatus'
            label='Suitability Report Status'
          >
            <Switch
              checked={
                initialValues?.suitabilityReportStatus === true ? true : false
              }
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='riskSuitabilityReportStatus'
            label='Risk Assessment Report'
          >
            <Switch
              checked={
                initialValues?.riskSuitabilityReportStatus === true
                  ? true
                  : false
              }
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='agreementReportStatus'
            label='Agreement Report Status'
          >
            <Switch
              checked={
                initialValues?.agreementReportStatus === true ? true : false
              }
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='rationalStatus' label='Rational Status'>
            <Switch
              checked={initialValues?.rationalStatus === true ? true : false}
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='invoiceStatus' label='Invoice Status'>
            <Switch
              checked={initialValues?.invoiceStatus === true ? true : false}
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='motherName'
            label='Mother Name'
            rules={[
              { required: true, message: 'Please input your Mother Name!' },
            ]}
          >
            <Input
              className='client-detail-input'
              style={{ textTransform: 'capitalize' }}
              placeholder={`Enter Mother's Name`}
              onChange={(e) => handleInputChange(form, 'motherName', e)}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='fatherName'
            label='Father Name'
            rules={[
              { required: true, message: 'Please input your Father Name!' },
            ]}
          >
            <Input
              className='client-detail-input'
              style={{ textTransform: 'capitalize' }}
              placeholder={`Enter Father's Name`}
              disabled={isClient}
              onChange={(e) => handleInputChange(form, 'fatherName', e)}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='address'
            label='Address'
            rules={[{ required: true, message: 'Please enter your Address!' }]}
          >
            <Input
              className='client-detail-input'
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter Address'
              disabled={isClient}
              onChange={(e) => handleInputChange(form, 'address', e)}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='countryId'
            label='Select your country'
            rules={[{ required: true, message: 'Please Select your country!' }]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              showSearch
              optionFilterProp='children'
              className='display-field'
              autoComplete='something-new'
              placeholder='Select your country'
              onChange={(value) => {
                setSelectedCountry(value)
                form.setFieldsValue({
                  stateId: undefined,
                  cityId: undefined,
                })
              }}
              disabled={isClient}
            >
              {countries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            // name='stateId'
            label='Select your state'
            rules={[{ required: true, message: 'Please Select your state!' }]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your state'
              notFoundContent='Please Select Your Country First!'
              className='display-field'
              value={selectedState}
              onChange={(value) => {
                setSelectedState(
                  states.filter((state) => state.id === value)[0].id
                )
                form.setFieldsValue({
                  cityId: undefined,
                })
              }}
              disabled={isClient}
            >
              {!!selectedCountry &&
                states.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='cityId'
            label='Select your city'
            rules={[{ required: true, message: 'Please Select your city!' }]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your city'
              notFoundContent='Please Select Your State First!'
              className='display-field'
              disabled={isClient}
            >
              {!!selectedState &&
                cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='clientCode'
            label='Client Code'
            rules={[
              { required: true, message: 'Please input your Client Code!' },
            ]}
          >
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter Client Code'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='strategyCode'
            label='Strategy Code'
            rules={[
              { required: true, message: 'Please input your Strategy Code!' },
            ]}
          >
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter Strategy Code'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='amcAmount'
            label='AMC Amount'
            // rules={[{ required: true, message: 'Please input your AMC Amount!' }]}
          >
            <InputNumber
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter AMC Amount'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='amcStartDate'
            label='AMC Start Date'
            // rules={[{ required: true, message: 'Please input your AMC Start Date!' }]}
          >
            <DatePicker
              className='client-detail-date-picker'
              getPopupContainer={(trigger) => trigger.parentElement}
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='sipAmount'
            label='SIP Amount'
            // rules={[{ required: true, message: 'Please input your SIP Amount!' }]}
          >
            <InputNumber
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter SIP Amount'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='sipStartDate'
            label='SIP Start Date'
            // rules={[{ required: true, message: 'Please input your SIP Start Date!' }]}
          >
            <DatePicker
              className='client-detail-date-picker'
              getPopupContainer={(trigger) => trigger.parentElement}
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='initialExposure'
            label='Initial Exposure'
            rules={[
              {
                required: true,
                message: 'Please input your Initial Exposure!',
              },
            ]}
          >
            <InputNumber
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter Initial Exposure'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='auaMaxLimit' label='AUA Max Limit'>
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter Annual Income'
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='exposure'
            label='Exposure'
            rules={[{ required: true, message: 'Please input your Exposure!' }]}
          >
            <InputNumber
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter Exposure'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='currentAUA' label='Current AUA'>
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter Current AUA'
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item label='Net Available AUA'>
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              placeholder='Enter Net Available AUA'
              value={
                initialValues?.annualIncome * 10 - initialValues?.currentAUA
              }
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='completedSteps' label='User Completed Step'>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className='display-field'
              placeholder='User Completed Step'
              disabled={isClient}
            >
              {defaultSteps.map((defaultStep) => (
                <Option key={defaultStep.key} value={defaultStep.key}>
                  {defaultStep.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='closedDate'
            label='Closed Date'
            // rules={[{ required: true, message: 'Please input your Closed Date!' }]}
          >
            <DatePicker
              className='client-detail-date-picker'
              getPopupContainer={(trigger) => trigger.parentElement}
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <Form.Item name='createdById' label='Select User'>
            {!users ? (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                allowClear
                loading={!users}
                placeholder='Select User'
                optionFilterProp='children'
                autoComplete='something-new'
                className='display-field'
                onChange={(value) => setSelectedUser(value)}
                disabled
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {getName(user)}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                allowClear
                loading={!users}
                placeholder='Select User'
                optionFilterProp='children'
                autoComplete='something-new'
                onChange={(value) => setSelectedUser(value)}
                disabled={isClient}
                className='display-field'
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {getName(user)}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {selectedUser && leadUsers && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <Row
              style={{
                padding: 24,
                margin: '24px 0',
                background: '#fbfbfb',
                border: '1px solid #d9d9d9',
              }}
            >
              <Col
                className='gutter-row client-column'
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <div
                  style={{
                    fontSize: 16,
                    marginBottom: 6,
                    textAlign: 'center',
                  }}
                >
                  Revenue Details
                </div>
              </Col>
              {leadUsers.map((leadUser, key) => {
                if (leadUser.role === 'ADMIN') return false
                return (
                  <Col
                    className='gutter-row client-column'
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    key={key}
                  >
                    <Form.Item
                      name={`ratio_${leadUser.id}`}
                      label={`${leadUser.name}'s Ratio (${
                        leadUser?.role?.replace('_', ' ') || ''
                      })`}
                      // rules={[
                      //   {
                      //     pattern: /^(?!0$)/,
                      //     message: 'Ratio cannot be 0',
                      //   },
                      // ]}
                    >
                      <InputNumber
                        min={0}
                        placeholder='Enter Ratio'
                        style={{ width: '100%' }}
                        disabled={isClient}
                        className='client-detail-input'
                        // parser={value => value.replace('%', '')}
                        // formatter={value => value ? `${value}%` : ''}
                      />
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          </Col>
        )}
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='memberName'
            label='Select Member'
            rules={[{ required: true, message: 'Please Select Member!' }]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              showSearch
              loading={memberLoading}
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select Member'
              disabled={isClient || userData?.getUser?.memberName === 6}
              className='display-field'
            >
              {members &&
                members.map((member) => (
                  <Option key={member.ID} value={member.ID}>
                    {member.MemberCode}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        {!isClient && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='plan'
              label='Select Plan'
              rules={[{ message: 'Please Select Plan!' }]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                loading={planLoading}
                optionFilterProp='children'
                autoComplete='something-new'
                placeholder='Select Plan'
                className='display-field'
              >
                {plans &&
                  plans.map((plan) => {
                    return (
                      <Option key={plan.id} value={plan.id}>
                        {plan.name}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name={['remark']} label='Remark'>
            <Input className='client-detail-input' disabled={isClient} />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='umrnNumber' label='UMRN Number'>
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name={['collateralValue']} label='Collateral Value'>
            <Input
              className='client-detail-input'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        {!isUpdate && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item name='createdAt' label='Created Date'>
              <DatePicker
                className='client-detail-date-picker'
                getPopupContainer={(trigger) => trigger.parentElement}
                format={'DD-MMM-YYYY'}
                autoComplete='off'
                style={{ width: '100%' }}
                disabled={isClient}
              />
            </Form.Item>
          </Col>
        )}
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='registrationDate' label='Registration Date'>
            <DatePicker
              className='client-detail-date-picker'
              getPopupContainer={(trigger) => trigger.parentElement}
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='investmentDate' label='Investment Date'>
            <DatePicker
              className='client-detail-date-picker'
              getPopupContainer={(trigger) => trigger.parentElement}
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='amcInPer' label='AMC in Percentage'>
            <Input
              className='client-detail-input'
              placeholder='Enter AMC Percentage'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='sourceOfLead' label='Source of Lead'>
            <Input
              className='client-detail-input'
              placeholder='Enter Source of Lead'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='associateIntroducer' label='Associate Introducer'>
            <Input
              className='client-detail-input'
              placeholder='Enter Associate Introducer'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='riskProfileType' label='Risk Profile Type'>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className='display-field'
              placeholder='Select Risk Profile Type'
              disabled={isClient}
            >
              <Option value={'High Risk'}> High Risk </Option>
              <Option value={'Moderate Risk'}> Moderate Risk </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='discountStatus' label='Discount Status'>
            <Input
              className='client-detail-input'
              type='number'
              placeholder='Enter Discount Status'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='commissionSelectUser' label='Commission Select User'>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              showSearch
              allowClear
              className='display-field'
              loading={!users}
              placeholder='Select Commission User'
              optionFilterProp='children'
              autoComplete='something-new'
              onChange={(value) => setCommissionSelectUser(value)}
              disabled={isClient}
              defaultValue={commissionSelectUser}
            >
              {users &&
                users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {getName(user)}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        {isUpdate && isAdmin && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item name='isReUploadKyc' label='Re-Upload Kyc'>
              <Switch
                defaultChecked={reUploadKyc}
                onClick={() => setReUploadKyc(!reUploadKyc)}
              />
            </Form.Item>
          </Col>
        )}

        {reUploadKyc && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='currentCompletedSteps'
              label='Current Completed Step'
            >
              <Input
                className='client-detail-input'
                autoComplete='off'
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
        )}
        {/* {isUpdate && !isClient && (
          <>
            <Col
              className='gutter-row client-column'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            >
              <div
                style={{
                  fontSize: 14,
                  margin: '8px 0',
                  color: '#535353',
                }}
              >
                Disable User:
              </div>
              <Form.Item name='isDisableDate' style={{ margin: '8px -12px' }}>
                <Switch
                  defaultChecked={disableChecked}
                  onClick={() => setDisableChecked(!disableChecked)}
                  disabled={isClient}
                />
              </Form.Item>

              {disableChecked && (
                <Form.Item
                  name='disableDate'
                  style={{ margin: '8px -12px' }}
                  rules={[
                    { required: true, message: 'Please input disable date!' },
                  ]}
                >
                  <DatePicker
                    className='client-detail-date-picker'
                    getPopupContainer={(trigger) => trigger.parentElement}
                    format={'DD-MMM-YYYY'}
                    placeholder='Select Disable Date'
                    autoComplete='off'
                    showToday={false}
                    style={{ width: '100%' }}
                    disabled={isClient}
                  />
                </Form.Item>
              )}
            </Col>
          </>
        )} */}
        <Col
          className='gutter-row client-column'
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item name='familyGroup' label='Family Group'>
            <Input
              className='client-detail-input'
              placeholder='Enter Family Group'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        {!isUpdate && !users && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          ></Col>
        )}
        {!isClient && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='panCard'
              label='PanCard'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='panCard-file'
                listType='text'
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
                fileList={fileList}
                onRemove={handleRemove}
                {...customProps}
                onChange={handleChange}
              >
                <Button disabled={isClient} style={{ width: '100%' }}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}
        {!isClient && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='signature'
              label='signature'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='signature-file'
                listType='text'
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
                fileList={fileList}
                onRemove={handleRemove}
                {...customProps}
                onChange={handleChange}
              >
                <Button disabled={isClient} style={{ width: '100%' }}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {!isClient && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='aadharCard'
              label='aadharCard'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='aadharCard-file'
                listType='text'
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
                fileList={fileList}
                onRemove={handleRemove}
                {...customProps}
                onChange={handleChange}
              >
                <Button disabled={isClient} style={{ width: '100%' }}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}
        {!isClient && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='passportSize'
              label='passportSize'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='passportSize-file'
                listType='text'
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
                fileList={fileList}
                onRemove={handleRemove}
                {...customProps}
                onChange={handleChange}
              >
                <Button disabled={isClient} style={{ width: '100%' }}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}
        {!isClient && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='bankStatement'
              label='Bank Statement'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='bankStatement-file'
                listType='text'
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
                fileList={fileList}
                onRemove={handleRemove}
                {...customProps}
                onChange={handleChange}
              >
                <Button disabled={isClient} style={{ width: '100%' }}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {!isClient && (
          <Col
            className='gutter-row client-column'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <Form.Item
              name='cancelledChequeSnap'
              label='Cancelled Cheque'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                maxCount={1}
                name='cancelledChequeSnap-file'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button disabled={isClient} style={{ width: '100%' }}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        <Col
          className='gutter-row client-column'
          style={{
            marginBottom: isMobile ? '3vh' : '1px',
          }}
          span={24}
        >
          <Form.Item {...tailFormItemLayout}>
            {!users ? (
              <Button type='primary' htmlType='submit' disabled>
                Save
              </Button>
            ) : (
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
                disabled={isClient}
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default ClientForm
