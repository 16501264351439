import React, { useContext, useEffect } from 'react'
import { Button, Layout, Steps } from 'antd'
import MainLogo from 'assets/images/new-images/Finideas-New-Logo.png'
import BurgerMenu from 'assets/images/new-images/burger-menu.svg'
import MainRoutes from 'MainRoutes'
import { AppContext } from 'AppContext'
import HeaderNew from './HeaderNew'
import Menu from './Menu'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import checkCircleForStep from 'assets/images/new-images/checkCircleForStep.svg'
import Footer from './footer'

const { Sider, Content } = Layout

const SiderNew = ({ currentUser, collapsed, setCollapsed, pathname }) => {
  const {
    state: { isAdmin, isClient, isMobile },
  } = useContext(AppContext)

  const { Step } = Steps

  const stepsForIndividual = [
    { title: 'Disclaimer' },
    { title: 'Select Type' },
    { title: 'Client Basic Details' },
    { title: 'Client Profile' },
    { title: 'Risk Profile Assessment' },
    { title: 'Summary' },
    { title: 'Kyc Documents' },
    { title: 'Kyc E-Sign' },
    { title: 'Investment Planning' },
    { title: 'Agreement Sign' },
    { title: 'E-Mandate Registration' },
    { title: 'Congratulations' },
    { title: 'Pending Advisory' },
  ]

  const stepsForNonIndividual = [
    { title: 'Disclaimer' },
    { title: 'Select Type' },
    { title: 'Client Basic Details' },
    { title: 'Client Profile' },
    { title: 'Risk Profile Assessment' },
    { title: 'Summary' },
    { title: 'Investment Planning' },
    { title: 'Courier Instruction' },
  ]

  let tempCurrent = 0
  switch (currentUser?.completedSteps) {
    case 'LOGIN_CREATED':
      tempCurrent = 1
      break
    case 'SELECT_USER_TYPE':
      tempCurrent = 2
      break
    case 'CLIENT_PROFILE_1':
      tempCurrent = 3
      break
    case 'CLIENT_PROFILE_2':
      tempCurrent = 4
      break
    case 'RISK_PROFILE':
      tempCurrent = 5
      break
    case 'SUMMARY':
      tempCurrent = 6
      break
    case 'KYC_DOC':
      tempCurrent = 7
      break
    case 'KYC_FORM':
      tempCurrent = 8
      break
    case 'INVESTMENT_PLANNING':
      tempCurrent = 9
      break
    case 'AGREEMENT_SIGN':
      tempCurrent = 10
      break
    case 'E_MANDATE':
      tempCurrent = 11
      break
    case 'CONGRATULATION':
      tempCurrent = 12
      break
    case 'DONE':
      tempCurrent = 13
      break
    default:
      break
  }

  const loadAiChatBot = () => {
    if (!isAdmin) {
      const script = document.createElement('script')
      script.src = 'https://chatling.ai/js/embed.js'
      script.async = true
      script.id = 'chatling-embed-script'
      script.setAttribute('data-id', '5276152696')

      const chatBotDiv = document.getElementById('chat-bot')
      if (chatBotDiv) {
        chatBotDiv.appendChild(script)
      }

      window.chtlConfig = { chatbotId: '5276152696' }

      return () => {
        if (chatBotDiv) {
          chatBotDiv.removeChild(script)
        }
      }
    }
  }

  useEffect(() => {
    loadAiChatBot()

    const checkChatBotLoaded = setInterval(() => {
      if (window.Chatling && typeof window.Chatling.open === 'function') {
        clearInterval(checkChatBotLoaded)
      }
    }, 500)

    return () => clearInterval(checkChatBotLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchClick = () => {
    if (window.Chatling && typeof window.Chatling.open === 'function') {
      window.Chatling.open()
    } else {
      console.error('Chatbot library is not fully loaded yet.')
    }
  }

  return (
    <Layout className='layout'>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={274}
        className={`sider ${
          collapsed ? 'sider-collapsed' : 'sider-expanded'
        } sider_new_scroll`}
      >
        <div className={`header ${collapsed ? 'header-collapsed' : ''}`}>
          {!collapsed && (
            <NavLink
              to='/'
              className='nav-text'
              style={{ display: `${isMobile && collapsed ? 'none' : 'block'}` }}
            >
              <img
                src={MainLogo}
                alt='Finideas Logo'
                className='logo'
                style={{ objectFit: 'cover' }}
              />
            </NavLink>
          )}
          <Button
            className='burger-menu-button'
            type='text'
            onClick={() => setCollapsed(!collapsed)}
          >
            <img
              style={{ width: collapsed ? '30px' : '100%' }}
              src={BurgerMenu}
              alt='Finideas Logo'
            />
          </Button>
        </div>
        <div className='menu-wrapper'>
          {currentUser?.completedSteps !== 'DONE' && isClient ? (
            <Steps direction='vertical' current={tempCurrent} status='process'>
              {(currentUser?.isPanCardIndividual
                ? stepsForIndividual
                : stepsForNonIndividual
              )?.map((step, index) => (
                <Step
                  key={index}
                  title={step.title}
                  icon={
                    index < tempCurrent ? (
                      <img src={checkCircleForStep} alt='checkCircleForStep' />
                    ) : null
                  }
                />
              ))}
            </Steps>
          ) : (
            <Menu pathname={pathname} setVisibleDrawer={setCollapsed} />
          )}
          {!isAdmin && <div id='chat-bot'></div>}
        </div>
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed
            ? isMobile
              ? 55
              : 90
            : currentUser?.completedSteps === 'DONE' || !isClient
            ? isMobile
              ? 0
              : 274
            : 0,
        }}
      >
        <HeaderNew />
        <Content className='content'>
          <div
            className={`content-wrapper ${
              isAdmin ? 'admin-content-wrapper' : ''
            }`}
            style={{ height: '100%' }}
          >
            <MainRoutes />
          </div>
        </Content>
        {currentUser?.role !== 'ADMIN' && !isMobile && (
          <Footer
            currentUser={currentUser}
            handleSearchClick={handleSearchClick}
          />
        )}
      </Layout>
    </Layout>
  )
}

export default SiderNew
