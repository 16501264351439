import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Button, InputNumber, Space, Table, Form } from 'antd'
import client from 'apollo'
import { GET_OPEN_POSITION_FOR_CLIENT } from './graphql/Queries'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import EditPencilIcon from 'assets/images/new-images/edit-pencil-icon.svg'
import './index.css'
import openNotification from 'utils/Notification'
import { UPDATE_OPEN_POSITION_FOR_CLIENT } from './graphql/Mutation'

const UpdatePosition = () => {
  const [loading, setLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState({})
  const [form] = Form.useForm()
  const [editingRowIndex, setEditingRowIndex] = useState(null)

  const getSpDataForCurrentPosition = () => {
    setLoading(true)
    client
      .query({
        query: GET_OPEN_POSITION_FOR_CLIENT,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setData(res?.data?.spGetCurrentPosition[0])
        setLoading(false)
        form.setFieldsValue({ positions: res?.data?.spGetCurrentPosition[0] })
      })
      .catch((err) => {
        setLoading(false)
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(
    () => {
      getSpDataForCurrentPosition()
    }, // eslint-disable-next-line
    []
  )

  const handleSave = () => {
    setDataLoading(true)
    const updatedData = form.getFieldValue('positions')
    client
      .mutate({
        mutation: UPDATE_OPEN_POSITION_FOR_CLIENT,
        variables: {
          data: updatedData,
        },
      })
      .then((res) => {
        setDataLoading(false)
        getSpDataForCurrentPosition()
        if (res?.data?.Sp_UpdateCurrPosValue?.Status === false) {
          openNotification(
            'error',
            `An error occurred at Script ${res?.data?.Sp_UpdateCurrPosValue?.script}`
          )
        } else {
          setEditingRowIndex(null)
          setIsEditing(false)
          openNotification('success', 'Updated position value successfully')
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleEdit = (index) => {
    setIsEditing(true)
    setEditingRowIndex(index)
  }

  const renderAction = (record, index) => {
    const { Rate, Qty } = record
    if (Rate !== 0 || Qty !== 0) {
      return (
        <Space size='middle'>
          <div style={{ cursor: 'pointer' }} onClick={() => handleEdit(index)}>
            <img src={EditPencilIcon} alt='EditPencilIcon' />
          </div>
        </Space>
      )
    }
  }

  const columns = [
    {
      title: 'Client Code',
      dataIndex: 'ClientCode',
      key: 'clientCode',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'script',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Quantity',
      dataIndex: 'Qty',
      key: 'Qty',
      render: (text, record, key) => {
        if (isEditing) {
          const isEdit = editingRowIndex === key
          return isEdit ? (
            <Form.Item
              name={['positions', key, 'Qty']}
              initialValue={record?.Qty}
              style={{ margin: 0 }}
              rules={[{ required: true, message: 'Quantity is required!' }]}
            >
              <InputNumber />
            </Form.Item>
          ) : (
            <span>{record?.Qty}</span>
          )
        } else if (
          !isEditing &&
          !data[key]?.Rate !== 0 &&
          !data[key]?.Rate > 0 &&
          !data[key]?.Qty > 0
        ) {
          return (
            <Form.Item
              name={['positions', key, 'Qty']}
              initialValue={record?.Qty}
              style={{ margin: 0 }}
              rules={[{ required: true, message: 'Quantity is required!' }]}
            >
              <InputNumber />
            </Form.Item>
          )
        } else {
          return <span>{record?.Qty}</span>
        }
      },
    },
    {
      title: 'Rate',
      dataIndex: 'Rate',
      key: 'Rate',
      render: (text, record, key) => {
        if (isEditing) {
          const isEdit = editingRowIndex === key
          return isEdit ? (
            <Form.Item
              name={['positions', key, 'Rate']}
              initialValue={record?.Rate}
              style={{ margin: 0 }}
              rules={[{ required: true, message: 'Rate is required!' }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          ) : (
            <span>{record?.Rate}</span>
          )
        } else if (
          !isEditing &&
          !data[key]?.Rate !== 0 &&
          !data[key]?.Rate > 0 &&
          !data[key]?.Qty > 0
        ) {
          return (
            <Form.Item
              name={['positions', key, 'Rate']}
              initialValue={record?.Rate}
              style={{ margin: 0 }}
              rules={[{ required: true, message: 'Rate is required!' }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          )
        } else {
          return <span>{record?.Rate}</span>
        }
      },
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => renderAction(record, index),
    },
  ]

  if (!isEmpty(error)) return <Page404 />

  return (
    <>
      <div className='update_position_main_container'>
        {!loading && data?.length ? (
          <>
            <div className='update_position_title_container'>
              <div className='update_position_header'>Update Position</div>
            </div>
            <Form form={form} onFinish={handleSave}>
              <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey='ClientCode'
                rowClassName={(record) =>
                  record?.Rate !== 0 || record?.Qty !== 0 ? 'edited-row' : ''
                }
              />
              <div className='update_Position_Submit_Button_Container'>
                <Button
                  type='primary'
                  className='updatePositionSubmitButton'
                  htmlType='submit'
                  loading={dataLoading}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        ) : loading ? (
          <Loader />
        ) : (
          <div className='no_position_found'>No Position Found</div>
        )}
      </div>
    </>
  )
}

export default UpdatePosition
